import React, { useState } from "react";
import MyContext from "./MyContext";

function MyProvider({ children }) {
  //Authentication
  const [count, setCount] = useState(0);
  const [dataFetched, setDataFetched] = useState(false);
  const [getIp, setGetIp] = useState(false);
  const [headers, setHeaders] = useState(null);
  const [idVerification, setIdVerification] = useState(false);
  const [idVerificationRequired, setIdVerificationRequired] = useState(false);
  const [idVerificationFailed, setIdVerificationFailed] = useState(false);
  const [requestAuthentication, setRequestAuthentication] = useState(false);

  //Data
  const [data, setData] = useState({
    //Authentication
    otp: "",

    //Location
    ip: "",
    position: { latitude: null, longitude: null },

    //Declaration Data
    termsAndConditions: "",
    signedLocation: "",
    signedDate: "",

    //Insured Data
    title: "",
    firstName: "",
    lastName: "",
    idType: "",
    saCitizen: "",
    idNumber: "",
    occupation: "",
    gender: "",
    dob: "",
    age: "",
    maritalStatus: "",
    insuredDriversLicenceCode: "",
    mobile: "",
    workTelNo: "",
    altTelNo: "",
    email: "",
    message: "",

    //Insured Street Address
    addressType: "",
    complex: "",
    streetAddress: "",
    suburb: "",
    city: "",
    province: "",
    postalCode: "",
    country: "",
    physicalPostalSame: "No",

    //Insured Postal Address
    postalComplex: "",
    postalAddress: "",
    postalSuburb: "",
    postalCity: "",
    postalProvince: "",
    postalPostalCode: "",

    //Insurance Data
    typeOfPolicy: "",
    currentlyInsured: "",
    insurerName: "",
    insurerDuration: "",
    insuranceAmount: "",
    insuranceUninterruptedPast36Months: "",
    insuranceClaimsLast12Months: "",
    insuranceClaimsLast24Months: "",
    insuranceClaimsLast36Months: "",
    carHireRequired: "",
    carHireType: "",
    carHirePremium: "",
    product: "",

    //Insured Vehicle Data
    vehicleYear: "",
    vehicleAge: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleMMCode: "",
    vehicleVinNo: "",
    vehicleEngineNo: "",
    vehicleRegNo: "",
    vehicleValue: 0,
    vehicleEstimatedValue: "",
    vehicleUse: "",
    vehicleDriveToWorkPerWeek: "",
    vehicleType: "",
    vehicleEngineCapacity: "",
    vehicleAntiTheftInstalled: "",
    vehicleAntiTheftDetails: "",
    vehicleModifications: "",
    vehicleModificationsDetails: "",
    vehicleImmobiliser: "",
    vehicleRegisteredCode3: "",
    insuranceSub: "",
    insuranceClaimSub20: "",
    insuranceClaimSub40: "",
    insuranceClaimSub60: "",
    insuranceClaimSub80: "",
    insuranceClaimSub100: "",
    insuranceClaimTotal20: "",
    insuranceClaimTotal40: "",
    insuranceClaimTotal60: "",
    insuranceClaimTotal80: "",
    insuranceClaimTotal100: "",

    //Insured Vehicle Risk Address
    riskAddressSame: "",
    riskAddressType: "",
    riskComplex: "",
    riskStreetAddress: "",
    riskSuburb: "",
    riskCity: "",
    riskProvince: "",
    riskPostalCode: "",

    //Insured Vehicle Day Address
    dayRiskAddressSame: "",
    dayAddressType: "",
    dayAddressSecure: "",
    dayComplex: "",
    dayStreetAddress: "",
    daySuburb: "",
    dayCity: "",
    dayProvince: "",
    dayPostalCode: "",

    //Insured Vehicle Night Address
    nightRiskAddressSame: "",
    nightAddressType: "",
    nightAddressSecure: "",
    nightComplex: "",
    nightStreetAddress: "",
    nightSuburb: "",
    nightCity: "",
    nightProvince: "",
    nightPostalCode: "",

    //Insured Vehicle Finance Data
    isVehicleFinanced: "",
    financeCompany: "",
    financeAgreement: "",
    financeAccountNo: "",
    financeBranch: "",
    financeOutstanding: "",
    creditShortfallExtension: "",

    //Insured Vehicle Regular Driver
    regularDriverSame: "",
    regularDriverTitle: "",
    regularDriverFirstName: "",
    regularDriverLastName: "",
    regularDriverIdType: "",
    regularDriverId: "",
    regularDriverOccupation: "",
    regularDriverGender: "",
    regularDriverDob: "",
    regularDriverAge: "",
    regularDriverMaritalStatus: "",
    regularDriverDriversLicenceCode: "",
    regularDriverMobile: "",
    regularDriverWorkTelNo: "",
    regularDriverAltTelNo: "",
    regularDriverEmail: "",

    //Registered Owner
    registeredOwnerSame: "",
    registeredOwnerTitle: "",
    registeredOwnerFirstName: "",
    registeredOwnerLastName: "",
    registeredOwnerIdType: "",
    registeredOwnerId: "",
    registeredOwnerOccupation: "",
    registeredOwnerGender: "",
    registeredOwnerDob: "",
    registeredOwnerAge: "",
    registeredOwnerMaritalStatus: "",
    registeredOwnerDriversLicenceCode: "",
    registeredOwnerMobile: "",
    registeredOwnerWorkTelNo: "",
    registeredOwnerAltTelNo: "",
    registeredOwnerEmail: "",

    //Docs
    form: "",
    idDocument: "",
    licenceDisk: "",
    licencePlate: "",
  });

  //Docs
  const [docsData, setDocsData] = useState({
    leadId: "",
    formName: "",
    formBase64: null,
    idDocumentName: "",
    idDocumentBase64: null,
    licenceDiskName: "",
    licenceDiskBase64: null,
    licencePlateName: "",
    licencePlateBase64: null,
  });
  const [docsSubmitted, setDocsSubmitted] = useState(false);
  const [docsSent, setDocsSent] = useState(false);
  const [docsError, setDocsError] = useState(false);
  const [docsResponse, setDocsResponse] = useState(false);

  //Errors
  const [acceptTermsError, setAcceptTermsError] = useState("");
  const [addressSameError, setAddressSameError] = useState("");
  const [addressSecureError, setAddressSecureError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [altNumberError, setAltNumberError] = useState("");
  const [antiTheftInstalledError, setAntiTheftInstalledError] = useState("");
  const [code3Error, setCode3Error] = useState("");
  const [consentBureauInformationError, setConsentBureauInformationError] =
    useState("");
  const [consentFraudIdentityCheckError, setConsentFraudIdentityCheckError] =
    useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [formError, setFormError] = useState("");
  const [homeNumberError, setHomeNumberError] = useState("");
  const [idNumberError, setIdNumberError] = useState("");
  const [immobiliserError, setImmobiliserError] = useState("");

  const [mobileError, setMobileError] = useState("");
  const [modificationsError, setModificationsError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [postalPostalCodeError, setPostalPostalCodeError] = useState("");
  const [selectError, setSelectError] = useState("");
  const [signatureError, setSignatureError] = useState("");
  const [underAdministrativeOrderError, setUnderAdministrativeOrderError] =
    useState("");
  const [underDebtReviewError, setUnderDebtReviewError] = useState("");
  const [underSequestrationError, setUnderSequestrationError] = useState("");
  const [vehiclesError, setVehiclesError] = useState("");
  const [vehicleYearError, setVehicleYearError] = useState("");
  const [witnessContactNumberError, setWitnessContactNumberError] =
    useState("");
  const [workNumberError, setWorkNumberError] = useState("");

  //Laminin
  const [lamininSubmitted, setLamininSubmitted] = useState(false);
  const [lamininSent, setLamininSent] = useState(false);
  const [lamininError, setLamininError] = useState(false);
  const [lamininResponse, setLamininResponse] = useState({});

  //Mail
  const [mailSubmitted, setMailSubmitted] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [mailError, setMailError] = useState(false);

  //Forms
  const [addressDataCaptured, setAddressDataCaptured] = useState(false);
  const [captureVehicle, setCaptureVehicle] = useState(false);
  const [dayAddressDataCaptured, setDayAddressDataCaptured] = useState(false);
  const [declarationDataCaptured, setDeclarationDataCaptured] = useState(false);
  const [docsDataCaptured, setDocsDataCaptured] = useState(false);
  const [financeDataCaptured, setFinanceDataCaptured] = useState(false);
  const [insuranceDataCaptured, setInsuranceDataCaptured] = useState(false);
  const [insuredDataCaptured, setInsuredDataCaptured] = useState(false);
  const [nightAddressDataCaptured, setNightAddressDataCaptured] =
    useState(false);
  const [quoteDataCaptured, setQuoteDataCaptured] = useState(false);
  const [registeredOwnerDataCaptured, setRegisteredOwnerDataCaptured] =
    useState(false);
  const [regularDriverDataCaptured, setRegularDriverDataCaptured] =
    useState(false);
  const [riskAddressDataCaptured, setRiskAddressDataCaptured] = useState(false);
  const [vehicleDataCaptured, setVehicleDataCaptured] = useState(false);
  const [vehiclesDataCaptured, setVehiclesDataCaptured] = useState(false);

  const [vehicles, setVehicles] = useState([]);

  return (
    <MyContext.Provider
      value={{
        //Authentication
        count,
        setCount,
        dataFetched,
        setDataFetched,
        getIp,
        setGetIp,
        headers,
        setHeaders,
        idVerification,
        setIdVerification,
        idVerificationRequired,
        setIdVerificationRequired,
        idVerificationFailed,
        setIdVerificationFailed,
        requestAuthentication,
        setRequestAuthentication,

        //Data
        data,
        setData,

        //Docs
        docsData,
        setDocsData,
        docsSubmitted,
        setDocsSubmitted,
        docsSent,
        setDocsSent,
        docsError,
        setDocsError,
        docsResponse,
        setDocsResponse,

        //Errors
        acceptTermsError,
        setAcceptTermsError,
        addressSameError,
        setAddressSameError,
        addressSecureError,
        setAddressSecureError,
        ageError,
        setAgeError,
        altNumberError,
        setAltNumberError,
        antiTheftInstalledError,
        setAntiTheftInstalledError,
        code3Error,
        setCode3Error,
        consentBureauInformationError,
        setConsentBureauInformationError,
        consentFraudIdentityCheckError,
        setConsentFraudIdentityCheckError,
        contactNumberError,
        setContactNumberError,
        formError,
        setFormError,
        homeNumberError,
        setHomeNumberError,
        idNumberError,
        setIdNumberError,
        immobiliserError,
        setImmobiliserError,
        mobileError,
        setMobileError,
        modificationsError,
        setModificationsError,
        postalCodeError,
        setPostalCodeError,
        postalPostalCodeError,
        setPostalPostalCodeError,
        selectError,
        setSelectError,
        signatureError,
        setSignatureError,
        underAdministrativeOrderError,
        setUnderAdministrativeOrderError,
        underDebtReviewError,
        setUnderDebtReviewError,
        underSequestrationError,
        setUnderSequestrationError,
        vehiclesError,
        setVehiclesError,
        vehicleYearError,
        setVehicleYearError,
        witnessContactNumberError,
        setWitnessContactNumberError,
        workNumberError,
        setWorkNumberError,

        //Laminin
        lamininSubmitted,
        setLamininSubmitted,
        lamininSent,
        setLamininSent,
        lamininError,
        setLamininError,
        lamininResponse,
        setLamininResponse,

        //Mail
        mailSubmitted,
        setMailSubmitted,
        mailSent,
        setMailSent,
        mailError,
        setMailError,

        //Forms
        addressDataCaptured,
        setAddressDataCaptured,
        captureVehicle,
        setCaptureVehicle,
        dayAddressDataCaptured,
        setDayAddressDataCaptured,
        declarationDataCaptured,
        setDeclarationDataCaptured,
        docsDataCaptured,
        setDocsDataCaptured,
        financeDataCaptured,
        setFinanceDataCaptured,
        insuranceDataCaptured,
        setInsuranceDataCaptured,
        insuredDataCaptured,
        setInsuredDataCaptured,
        nightAddressDataCaptured,
        setNightAddressDataCaptured,
        quoteDataCaptured,
        setQuoteDataCaptured,
        registeredOwnerDataCaptured,
        setRegisteredOwnerDataCaptured,
        regularDriverDataCaptured,
        setRegularDriverDataCaptured,
        riskAddressDataCaptured,
        setRiskAddressDataCaptured,
        vehicleDataCaptured,
        setVehicleDataCaptured,
        vehiclesDataCaptured,
        setVehiclesDataCaptured,

        //Vehicle Data
        vehicles,
        setVehicles,
      }}
    >
      {children}
    </MyContext.Provider>
  );
}

export default MyProvider;
