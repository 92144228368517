//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../../context/MyContext";

//Data
import { ProvinceData } from "../../../data/ProvinceData";

//Styled Components
import { Col } from "../../styles/Col";
import { Dropdown } from "../../styles/Dropdown.styled";
import { Form } from "../../styles/Form.styled";
import GoogleAddress from "../../Shared/GoogleAddress";
import { Input } from "../../styles/Input.styled";
import RadioButton from "../../styles/RadioButton";
import { Row } from "../../styles/Row";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormDescription = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormLabel = styled.label`
  padding: ${(props) => props.theme.space[1]};
  font-size: medium;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.primary};
`;

const ItemInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
  color: red;
`;

export default function RiskAddress(props) {
  const {
    data,
    setData,
    addressSameError,
    setAddressSameError,
    formError,
    setFormError,
    postalCodeError,
    setPostalCodeError,
    setFinanceDataCaptured,
    setRiskAddressDataCaptured,
  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => setFinanceDataCaptured(false);

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    if (name === "riskAddressSame" && value === "Yes") {
      setData((prevValue) => {
        return {
          ...prevValue,
          riskAddressType: data.addressType,
          riskComplex: data.complex,
          riskStreetAddress: data.streetAddress,
          riskSuburb: data.suburb,
          riskCity: data.city,
          riskProvince: data.province,
          riskPostalCode: data.postalCode,
        };
      });
    }

    if (name === "riskAddressSame" && value === "No") {
      setData((prevValue) => {
        return {
          ...prevValue,
          riskAddressType: "",
          riskComplex: "",
          riskStreetAddress: "",
          riskSuburb: "",
          riskCity: "",
          riskProvince: "",
          riskPostalCode: "",
        };
      });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    if (data.riskAddressSame === "") {
      setAddressSameError("Please select an option");
      isValid = false;
    } else {
      setAddressSameError("");
      setFormError("");
    }

    // Postal code validation
    if (data.riskPostalCode.length !== 4 || isNaN(data.riskPostalCode)) {
      setPostalCodeError(
        "Please enter a valid 4-digit Postal Code without any spaces"
      );
      isValid = false;
    } else {
      setPostalCodeError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      setRiskAddressDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormSubHeading>Vehicle Risk Address</FormSubHeading>
      <FormDescription>
        Please complete vehicle risk address information below
      </FormDescription>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <ItemWrapper>
          <Label>
            Is the Risk Address the same as the Insured Address? (Required)
          </Label>
          <ItemInnerWrapper>
            <RadioButton
              name="riskAddressSame"
              id="Yes"
              onChange={handleInfoChange}
              value="Yes"
              checked={data.riskAddressSame === "Yes"}
              label="Yes"
              required={true}
            />
            <RadioButton
              name="riskAddressSame"
              id="No"
              onChange={handleInfoChange}
              value="No"
              checked={data.riskAddressSame === "No"}
              label="No"
              required={true}
            />
          </ItemInnerWrapper>
          {addressSameError && (
            <ValidationSubHeading>{addressSameError}</ValidationSubHeading>
          )}
        </ItemWrapper>

        {data.riskAddressSame === "No" && (
          <div>
            <ItemWrapper>
              <FormLabel>Google Address Search</FormLabel>
              <GoogleAddress location="risk" />
            </ItemWrapper>
            <ItemWrapper>
              <FormLabel>Physical Address Information</FormLabel>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Unit / Complex</Label>
                  <Input
                    type="text"
                    name="riskComplex"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={false}
                    value={data.riskComplex}
                    width="100%"
                  />
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Street Address (Required)</Label>
                  <Input
                    type="text"
                    name="riskStreetAddress"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.riskStreetAddress}
                    width="100%"
                  />
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Suburb (Required)</Label>
                  <Input
                    type="text"
                    name="riskSuburb"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.riskSuburb}
                    width="100%"
                  />
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>City (Required)</Label>
                  <Input
                    type="text"
                    name="riskCity"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.riskCity}
                    width="100%"
                  />
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Province (Required)</Label>
                  <Dropdown
                    name="riskProvince"
                    onChange={handleInfoChange}
                    required={true}
                    value={data.riskProvince}
                    width="100%"
                  >
                    <option value="" disabled hidden>
                      Province
                    </option>
                    {ProvinceData.map((province, index) => {
                      return (
                        <option value={province.name} key={index}>
                          {province.name}
                        </option>
                      );
                    })}
                  </Dropdown>
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Postal Code (Required)</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    onWheel={(e) => e.target.blur()}
                    name="riskPostalCode"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.riskPostalCode}
                    width="100%"
                  />
                  {postalCodeError && (
                    <ValidationMessage>{postalCodeError}</ValidationMessage>
                  )}
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Type of Residence (Required)</Label>
                  <Dropdown
                    name="riskAddressType"
                    onChange={handleInfoChange}
                    required={true}
                    value={data.riskAddressType}
                    width="100%"
                  >
                    <option value="" disabled hidden>
                      Please select
                    </option>
                    <option value="City">City</option>
                    <option value="Country Estate">Country Estate</option>
                    <option value="Estate">Estate</option>
                    <option value="Township Area">Township Area</option>
                    <option value="Urban">Urban</option>
                  </Dropdown>
                </Col>
              </Row>
            </ItemWrapper>
          </div>
        )}
      </FormBody>
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
