//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Data
import { InsurerData } from "../../data/InsurerData";

//Styled Components
import { Col } from "../styles/Col";
import { Dropdown } from "../styles/Dropdown.styled";
import { Form } from "../styles/Form.styled";
import { Input } from "../styles/Input.styled";
import { Row } from "../styles/Row";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormDescription = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormLabel = styled.label`
  padding: ${(props) => props.theme.space[1]};

  font-size: medium;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.primary};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
  color: red;
`;

export default function Insurance(props) {
  const {
    data,
    setData,
    setAddressDataCaptured,
    setInsuranceDataCaptured,

    //Errors
    formError,
    setFormError,
  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => setAddressDataCaptured(false);

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      setInsuranceDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormDescription>
        Please complete the applicant insurance information below
      </FormDescription>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <ItemWrapper>
          <FormLabel>Existing Insurance Information</FormLabel>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Are you currently insured? (Required)</Label>
              <Dropdown
                name="currentlyInsured"
                onChange={handleInfoChange}
                required={true}
                value={data.currentlyInsured}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Dropdown>
            </Col>
          </Row>
          {data.currentlyInsured === "Yes" && (
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Current Insurer</Label>
                <Dropdown
                  name="insurerName"
                  onChange={handleInfoChange}
                  required={false}
                  value={data.insurerName}
                  width="100%"
                >
                  <option value="" disabled hidden>
                    Please select
                  </option>
                  {InsurerData.map((insurer, index) => {
                    return (
                      <option value={insurer.name} key={index}>
                        {insurer.name}
                      </option>
                    );
                  })}
                </Dropdown>
              </Col>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Current Insurance Premium</Label>
                <Input
                  type="text"
                  name="insuranceAmount"
                  onChange={handleInfoChange}
                  placeholder=""
                  required={false}
                  value={data.insuranceAmount}
                  width="100%"
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Duration of Current Insurance (Required)</Label>
              <Dropdown
                name="insurerDuration"
                onChange={handleInfoChange}
                required={true}
                value={data.insurerDuration}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Not currently insured">
                  Not currently insured
                </option>
                <option value="Less than 3 months">Less than 3 months</option>
                <option value="Less than 6 months">Less than 6 months</option>
                <option value="Less than 9 months">Less than 9 months</option>
                <option value="Less than 12 months">Less than 12 months</option>
                <option value="Less than 15 months">Less than 15 months</option>
                <option value="Less than 18 months">Less than 18 months</option>
                <option value="Less than 24 months">Less than 24 months</option>
                <option value="Less than 36 months">Less than 36 months</option>
                <option value="Over 36 months">Over 36 months</option>
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>
                Uninterrupted Vehicle Insurance Past 36 Months (Required)
              </Label>
              <Dropdown
                name="insuranceUninterruptedPast36Months"
                onChange={handleInfoChange}
                required={true}
                value={data.insuranceUninterruptedPast36Months}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Not Sure">Not Sure</option>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Number of Claims Last 12 Months (Required)</Label>
              <Dropdown
                name="insuranceClaimsLast12Months"
                onChange={handleInfoChange}
                required={true}
                value={data.insuranceClaimsLast12Months}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="2+">2+</option>
                <option value="1">1</option>
                <option value="0">0</option>
                <option value="Lights">Lights</option>
                <option value="Windscreen">Windscreen</option>
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Number of Claims Last 24 Months (Required)</Label>
              <Dropdown
                name="insuranceClaimsLast24Months"
                onChange={handleInfoChange}
                required={true}
                value={data.insuranceClaimsLast24Months}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="3+">3+</option>
                <option value="2">2</option>
                <option value="1">1</option>
                <option value="0">0</option>
                <option value="Lights">Lights</option>
                <option value="Windscreen">Windscreen</option>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Number of Claims Last 36 Months (Required)</Label>
              <Dropdown
                name="insuranceClaimsLast36Months"
                onChange={handleInfoChange}
                required={true}
                value={data.insuranceClaimsLast36Months}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="5+">5+</option>
                <option value="4">4</option>
                <option value="3">3</option>
                <option value="2">2</option>
                <option value="1">1</option>
                <option value="0">0</option>
                <option value="Lights">Lights</option>
                <option value="Windscreen">Windscreen</option>
              </Dropdown>
            </Col>
          </Row>
        </ItemWrapper>
        <ItemWrapper>
          <FormLabel>New Insurance Information</FormLabel>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Do you require car hire (Required)</Label>
              <Dropdown
                name="carHireRequired"
                onChange={handleInfoChange}
                required={true}
                value={data.carHireRequired}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Dropdown>
            </Col>
            {data.carHireRequired === "Yes" && (
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Car Hire Type (Required)</Label>
                <Dropdown
                  name="carHireType"
                  onChange={handleInfoChange}
                  required={true}
                  value={data.carHireType}
                  width="100%"
                >
                  <option value="" disabled hidden>
                    Please select
                  </option>
                  <option value="Economy">Economy</option>
                  <option value="Standard">Standard</option>
                  <option value="Luxury">Luxury</option>
                </Dropdown>
              </Col>
            )}
          </Row>
        </ItemWrapper>
      </FormBody>
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
