//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Styled Components
import { Col } from "../styles/Col";
import { Dropdown } from "../styles/Dropdown.styled";
import { Form } from "../styles/Form.styled";
import { Input } from "../styles/Input.styled";
import { Row } from "../styles/Row";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormDescription = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormLabel = styled.label`
  padding: ${(props) => props.theme.space[1]};

  font-size: medium;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.primary};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
  color: red;
`;

export default function Insured(props) {
  const {
    data,
    setData,
    setInsuredDataCaptured,

    //Errors
    altNumberError,
    setAltNumberError,
    formError,
    setFormError,
    idNumberError,
    setIdNumberError,
    mobileError,
    setMobileError,
    workNumberError,
    setWorkNumberError,
  } = useContext(MyContext);

  const currentDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const calculateAge = (birthDate, otherDate) => {
    birthDate = new Date(birthDate);
    otherDate = new Date(otherDate);
    var years = otherDate.getFullYear() - birthDate.getFullYear();
    if (
      otherDate.getMonth() < birthDate.getMonth() ||
      (otherDate.getMonth() === birthDate.getMonth() &&
        otherDate.getDate() < birthDate.getDate())
    ) {
      years--;
    }
    return years;
  };

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    // Create DOB from ID Number
    if (
      data.idType === "ID Document" &&
      name === "idNumber" &&
      value.length === 13 &&
      !data.dob
    ) {
      const century = currentDate.toString().substring(0, 2);
      const year = currentDate.toString().substring(2, 4);
      const idYear = value.substring(0, 2);
      let dateString = "";

      if (idYear >= 0 && idYear <= year) {
        dateString = `${century}${value.substring(0, 2)}-${value.substring(
          2,
          4
        )}-${value.substring(4, 6)}`;
      } else {
        dateString = `${century - 1}${value.substring(0, 2)}-${value.substring(
          2,
          4
        )}-${value.substring(4, 6)}`;
      }

      const calculatedDOB = new Date(dateString).toISOString().split("T")[0];

      setData((prevValue) => {
        return {
          ...prevValue,
          dob: calculatedDOB,
        };
      });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    // ID number validation
    if (data.idNumber.length !== 13 || isNaN(data.idNumber)) {
      setIdNumberError(
        "Please enter a valid 13-digit ID number without any spaces"
      );
      isValid = false;
    } else {
      setIdNumberError("");
      setFormError("");
    }

    // Mobile number validation
    if (data.mobile.length !== 10 || isNaN(data.mobile)) {
      setMobileError(
        "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
      );
      isValid = false;
    } else {
      setMobileError("");
      setFormError("");
    }

    // Work number validation
    if (data.workTelNo !== "") {
      if (data.workTelNo.length !== 10 || isNaN(data.workTelNo)) {
        setWorkNumberError(
          "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
        );
        isValid = false;
      } else {
        setWorkNumberError("");
        setFormError("");
      }
    }

    // Alt number validation
    if (data.altTelNo !== "") {
      if (data.altTelNo.length !== 10 || isNaN(data.altTelNo)) {
        setAltNumberError(
          "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
        );
        isValid = false;
      } else {
        setAltNumberError("");
        setFormError("");
      }
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      const calculatedAge = calculateAge(data.dob, currentDate);

      setData((prevValue) => {
        return {
          ...prevValue,
          age: calculatedAge,
        };
      });
      setInsuredDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormDescription>
        Please complete the applicant information below
      </FormDescription>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <ItemWrapper>
          <FormLabel>Insured Personal Information</FormLabel>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Title</Label>
              <Dropdown
                name="title"
                onChange={handleInfoChange}
                required={false}
                value={data.title}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Ms">Ms</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>First Name (Required)</Label>
              <Input
                type="text"
                name="firstName"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.firstName}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Last Name (Required)</Label>
              <Input
                type="text"
                name="lastName"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.lastName}
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Identity Type (Required)</Label>
              <Dropdown
                name="idType"
                onChange={handleInfoChange}
                required={true}
                value={data.idType}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="ID Document">ID Document</option>
                <option value="Passport">Passport</option>
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>ID Number (Required)</Label>
              <Input
                type="text"
                name="idNumber"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.idNumber}
                width="100%"
                maxLength={13}
              />
              {idNumberError && (
                <ValidationMessage>{idNumberError}</ValidationMessage>
              )}
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Mobile Telephone Number (Required)</Label>
              <Input
                type="text"
                name="mobile"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.mobile}
                width="100%"
                maxLength={10}
              />
              {mobileError && (
                <ValidationMessage>{mobileError}</ValidationMessage>
              )}
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Work Telephone Number</Label>
              <Input
                type="text"
                name="workTelNo"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.workTelNo}
                width="100%"
                maxLength={10}
              />
              {workNumberError && (
                <ValidationMessage>{workNumberError}</ValidationMessage>
              )}
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Alternative Telephone Number</Label>
              <Input
                type="text"
                name="altTelNo"
                onChange={handleInfoChange}
                placeholder=""
                required={false}
                value={data.altTelNo}
                width="100%"
                maxLength={10}
              />
              {altNumberError && (
                <ValidationMessage>{altNumberError}</ValidationMessage>
              )}
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Email (Required)</Label>
              <Input
                type="email"
                name="email"
                onChange={handleInfoChange}
                placeholder=""
                required={true}
                value={data.email}
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Occupation (Required)</Label>
              <Dropdown
                name="occupation"
                onChange={handleInfoChange}
                required={true}
                value={data.occupation}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Partially Skilled">Partially Skilled</option>
                <option value="Skilled">Skilled</option>
                <option value="Unskilled">Unskilled</option>
                <option value="Business Owner">Business Owner</option>
                <option value="Professional">Professional</option>
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Gender (Required)</Label>
              <Dropdown
                name="gender"
                onChange={handleInfoChange}
                required={true}
                value={data.gender}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Date of Birth (Required)</Label>
              <Input
                type="text"
                name="dob"
                onChange={handleInfoChange}
                placeholder=""
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                required={true}
                value={data.dob}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Marital Status (Required)</Label>
              <Dropdown
                name="maritalStatus"
                onChange={handleInfoChange}
                required={true}
                value={data.maritalStatus}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Single">Single</option>
                <option value="In a relationship">In a relationship</option>
                <option value="Married">Married</option>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Driver's Licence Code (Required)</Label>
              <Dropdown
                name="insuredDriversLicenceCode"
                onChange={handleInfoChange}
                required={true}
                value={data.insuredDriversLicenceCode}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Code 8">Code 8</option>
                <option value="Code 10">Code 10</option>
                <option value="Code 14">Code 14</option>
                <option value="Advanced Driving">Advanced Driving</option>
              </Dropdown>
            </Col>
          </Row>
        </ItemWrapper>
      </FormBody>
      <ButtonWrapper>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
