//NPM Packages
import { React, useContext, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";

//Context
import MyContext from "../../../context/MyContext";

//Styled Components
import { Col } from "../../styles/Col";
import { Dropdown } from "../../styles/Dropdown.styled";
import { Form } from "../../styles/Form.styled";
import { Input } from "../../styles/Input.styled";
import RadioButton from "../../styles/RadioButton";
import { Row } from "../../styles/Row";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormDescription = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
`;

const FormLabel = styled.label`
  padding: ${(props) => props.theme.space[1]};
  font-size: medium;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.primary};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ItemInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
  color: red;
`;

export default function Vehicle(props) {
  const {
    data,
    setData,
    headers,

    //Errors
    antiTheftInstalledError,
    setAntiTheftInstalledError,
    code3Error,
    setCode3Error,
    formError,
    setFormError,
    immobiliserError,
    setImmobiliserError,
    modificationsError,
    setModificationsError,

    setCaptureVehicle,
    setVehicleDataCaptured,
  } = useContext(MyContext);

  const [requestVehicleYear, setRequestVehicleYear] = useState(false);
  const [vehicleYearReceived, setVehicleYearReceived] = useState(false);
  const [vehicleYearError, setVehicleYearError] = useState(false);
  const [vehicleYearObject, setVehicleYearObject] = useState([]);
  const [requestVehicleMake, setRequestVehicleMake] = useState(false);
  const [vehicleMakeReceived, setVehicleMakeReceived] = useState(false);
  const [vehicleMakeError, setVehicleMakeError] = useState(false);
  const [vehicleMakeObject, setVehicleMakeObject] = useState([]);
  const [requestVehicleModel, setRequestVehicleModel] = useState(false);
  const [vehicleModelReceived, setVehicleModelReceived] = useState(false);
  const [vehicleModelError, setVehicleModelError] = useState(false);
  const [vehicleModelObject, setVehicleModelObject] = useState([]);
  const [requestVehicleVariant, setRequestVehicleVariant] = useState(false);
  const [vehicleVariantReceived, setVehicleVariantReceived] = useState(false);
  const [vehicleVariantError, setVehicleVariantError] = useState(false);
  const [vehicleVariantObject, setVehicleVariantObject] = useState([]);
  const [requestVehicleValue, setRequestVehicleValue] = useState(false);
  const [vehicleValueReceived, setVehicleValueReceived] = useState(false);
  const [vehicleValueError, setVehicleValueError] = useState(false);
  const [vehicleValueObject, setVehicleValueObject] = useState([]);

  const year = new Date().getFullYear();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Get Vehicle Registration Year Object
  useEffect(() => {
    if (!requestVehicleYear) {
      setRequestVehicleYear(true);
      // Enable this for local testing environment
      axios
        // .post("http://localhost:5000/api/vehicle/get-year", {}, {
        // Enable this for hosted production environment
        .post(
          "api/vehicle/get-year",
          {},
          {
            headers: headers,
          }
        )
        .then(
          (response) => {
            if (response.status === 200) {
              setVehicleYearReceived(true);
              setVehicleYearObject(response.data);
              console.log("Laminin Response OK: " + response.status);
            } else {
              setVehicleYearError(true);
              console.log("Laminin Response Not OK: " + response.status);
            }
          },
          (error) => {
            setVehicleYearError(true);
            console.log("Laminin Response Error: " + error);
          }
        );
    }
  }, [data, requestVehicleYear, headers]);

  const back = () => {
    setCaptureVehicle(false);
  };

  const getVehicleMake = (year) => {
    setRequestVehicleMake(true);
    // Enable this for local testing environment
    axios
      // .post("http://localhost:5000/api/vehicle/get-make", { year: year }, {
      // Enable this for hosted production environment
      .post(
        "api/vehicle/get-make",
        { year: year },
        {
          headers: headers,
        }
      )
      .then(
        (response) => {
          if (response.status === 200) {
            setVehicleMakeReceived(true);
            setVehicleMakeObject(response.data);
            console.log("Laminin Response OK: " + response.status);
          } else {
            setVehicleMakeError(true);
            console.log("Laminin Response Not OK: " + response.status);
          }
        },
        (error) => {
          setVehicleMakeError(true);
          console.log("Laminin Response Error: " + error);
        }
      );
  };

  const getVehicleModel = (year, make) => {
    setRequestVehicleModel(true);
    // Enable this for local testing environment
    axios
      // .post("http://localhost:5000/api/vehicle/get-model", { year: year, make: make }, {
      // Enable this for hosted production environment
      .post(
        "api/vehicle/get-model",
        { year: year, make: make },
        {
          headers: headers,
        }
      )
      .then(
        (response) => {
          if (response.status === 200) {
            setVehicleModelReceived(true);
            setVehicleModelObject(response.data);
            console.log("Laminin Response OK: " + response.status);
          } else {
            setVehicleModelError(true);
            console.log("Laminin Response Not OK: " + response.status);
          }
        },
        (error) => {
          setVehicleModelError(true);
          console.log("Laminin Response Error: " + error);
        }
      );
  };

  const getVehicleValue = (year, mmcode) => {
    setRequestVehicleValue(true);
    // Enable this for local testing environment
    axios
      // .post("http://localhost:5000/api/vehicle/get-value", { year: year, mmcode: mmcode }, {
      // Enable this for hosted production environment
      .post(
        "api/vehicle/get-value",
        { year: year, mmcode: mmcode },
        {
          headers: headers,
        }
      )
      .then(
        (response) => {
          if (response.status === 200) {
            let value = 0;
            if (response.data[0].RetailValue === 0) {
              value = response.data[0].NewValue;
            } else {
              value = response.data[0].RetailValue;
            }
            setVehicleValueReceived(true);
            setData((prevValue) => {
              return {
                ...prevValue,
                vehicleValue: value,
              };
            });
            console.log("Laminin Response OK: " + response.status);
          } else {
            setVehicleValueError(true);
            console.log("Laminin Response Not OK: " + response.status);
          }
        },
        (error) => {
          setVehicleValueError(true);
          console.log("Laminin Response Error: " + error);
        }
      );
  };

  const getVehicleVariant = (year, make, model) => {
    setRequestVehicleVariant(true);
    // Enable this for local testing environment
    axios
      // .post("http://localhost:5000/api/vehicle/get-variant", { year: year, make: make, model: model }, {
      // Enable this for hosted production environment
      .post(
        "api/vehicle/get-variant",
        { year: year, make: make, model: model },
        {
          headers: headers,
        }
      )
      .then(
        (response) => {
          if (response.status === 200) {
            setVehicleVariantReceived(true);
            setVehicleVariantObject(response.data);
            console.log("Laminin Response OK: " + response.status);
          } else {
            setVehicleVariantError(true);
            console.log("Laminin Response Not OK: " + response.status);
          }
        },
        (error) => {
          setVehicleVariantError(true);
          console.log("Laminin Response Error: " + error);
        }
      );
  };

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    if (name === "vehicleYear") {
      const vehicleAge = year - value;
      setRequestVehicleMake(false);
      setVehicleMakeReceived(false);
      setVehicleMakeError(false);
      setVehicleMakeObject([]);

      setRequestVehicleModel(false);
      setVehicleModelReceived(false);
      setVehicleModelError(false);
      setVehicleModelObject([]);

      setRequestVehicleVariant(false);
      setVehicleVariantReceived(false);
      setVehicleVariantError(false);
      setVehicleVariantObject([]);

      setRequestVehicleValue(false);
      setVehicleValueReceived(false);
      setVehicleValueError(false);
      setVehicleValueObject([]);

      setData((prevValue) => {
        return {
          ...prevValue,
          vehicleAge: vehicleAge,
          vehicleMake: "",
          vehicleModel: "",
          vehicleMMCode: "",
        };
      });

      getVehicleMake(value);
    }

    if (name === "vehicleMake") {
      setRequestVehicleModel(false);
      setVehicleModelReceived(false);
      setVehicleModelError(false);
      setVehicleModelObject([]);

      setRequestVehicleVariant(false);
      setVehicleVariantReceived(false);
      setVehicleVariantError(false);
      setVehicleVariantObject([]);

      setRequestVehicleValue(false);
      setVehicleValueReceived(false);
      setVehicleValueError(false);
      setVehicleValueObject([]);

      setData((prevValue) => {
        return {
          ...prevValue,
          vehicleModel: "",
          vehicleMMCode: "",
        };
      });

      getVehicleModel(data.vehicleYear, value);
    }

    if (name === "vehicleModel") {
      setRequestVehicleVariant(false);
      setVehicleVariantReceived(false);
      setVehicleVariantError(false);
      setVehicleVariantObject([]);

      setRequestVehicleValue(false);
      setVehicleValueReceived(false);
      setVehicleValueError(false);
      setVehicleValueObject([]);

      setData((prevValue) => {
        return {
          ...prevValue,
          vehicleMMCode: "",
        };
      });

      getVehicleVariant(data.vehicleYear, data.vehicleMake, value);
    }

    if (name === "vehicleMMCode") {
      setRequestVehicleValue(false);
      setVehicleValueReceived(false);
      setVehicleValueError(false);
      setVehicleValueObject([]);

      getVehicleValue(data.vehicleYear, value);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    // Anti Theft Installed Not Selected
    if (data.vehicleAntiTheftInstalled === "") {
      setAntiTheftInstalledError("Please select an option");
      isValid = false;
    } else {
      setAntiTheftInstalledError("");
      setFormError("");
    }

    // Vehicle Registered as Code 3 Not Selected
    if (data.vehicleRegisteredCode3 === "") {
      setCode3Error("Please select an option");
      isValid = false;
    } else {
      setCode3Error("");
      setFormError("");
    }

    // Vehicle fitted with an immobiliser Not Selected
    if (data.vehicleImmobiliser === "") {
      setImmobiliserError("Please select an option");
      isValid = false;
    } else {
      setImmobiliserError("");
      setFormError("");
    }

    // Vehicle Modifications Not Selected
    if (data.vehicleModifications === "") {
      setModificationsError("Please select an option");
      isValid = false;
    } else {
      setModificationsError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      setVehicleDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormDescription>
        Please complete the insured vehicle information below
      </FormDescription>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <ItemWrapper>
          <FormLabel>Insured Vehicle Details</FormLabel>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Vehicle Registration Year (Required)</Label>
              <Dropdown
                name="vehicleYear"
                onChange={handleInfoChange}
                required={true}
                value={data.vehicleYear}
                width="100%"
              >
                <option value="" disabled hidden>
                  {requestVehicleYear &&
                    !vehicleYearError &&
                    !vehicleYearReceived &&
                    "Loading..."}
                  {requestVehicleYear &&
                    vehicleYearError &&
                    "Error loading Vehicle Year"}
                  {requestVehicleYear && vehicleYearReceived && "Please select"}
                </option>
                {vehicleYearObject.map((vehicleYear, index) => {
                  return (
                    <option value={vehicleYear.RegYear} key={index}>
                      {vehicleYear.RegYear}
                    </option>
                  );
                })}
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Vehicle Make (Required)</Label>
              <Dropdown
                name="vehicleMake"
                onChange={handleInfoChange}
                required={true}
                value={data.vehicleMake}
                width="100%"
              >
                <option value="" disabled hidden>
                  {!requestVehicleMake &&
                    "Please select Vehicle Registration Year"}
                  {requestVehicleMake &&
                    !vehicleMakeError &&
                    !vehicleMakeReceived &&
                    "Loading..."}
                  {requestVehicleMake &&
                    vehicleMakeError &&
                    "Error loading Vehicle Make"}
                  {requestVehicleMake && vehicleMakeReceived && "Please select"}
                </option>
                {vehicleMakeObject.map((vehicleMake, index) => {
                  return (
                    <option value={vehicleMake.Make} key={index}>
                      {vehicleMake.Make}
                    </option>
                  );
                })}
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Vehicle Model (Required)</Label>
              <Dropdown
                name="vehicleModel"
                onChange={handleInfoChange}
                required={true}
                value={data.vehicleModel}
                width="100%"
              >
                <option value="" disabled hidden>
                  {!requestVehicleModel && "Please select Vehicle Make"}
                  {requestVehicleModel &&
                    !vehicleModelError &&
                    !vehicleModelReceived &&
                    "Loading..."}
                  {requestVehicleModel &&
                    vehicleModelError &&
                    "Error loading Vehicle Model"}
                  {requestVehicleModel &&
                    vehicleModelReceived &&
                    "Please select"}
                </option>
                {vehicleModelObject.map((vehicleModel, index) => {
                  return (
                    <option value={vehicleModel.Model} key={index}>
                      {vehicleModel.Model}
                    </option>
                  );
                })}
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Vehicle Variant (Required)</Label>
              <Dropdown
                name="vehicleMMCode"
                onChange={handleInfoChange}
                required={true}
                value={data.vehicleMMCode}
                width="100%"
              >
                <option value="" disabled hidden>
                  {!requestVehicleVariant && "Please select Vehicle Make"}
                  {requestVehicleVariant &&
                    !vehicleVariantError &&
                    !vehicleVariantReceived &&
                    "Loading..."}
                  {requestVehicleVariant &&
                    vehicleVariantError &&
                    "Error loading Vehicle Variant"}
                  {requestVehicleVariant &&
                    vehicleVariantReceived &&
                    "Please select"}
                </option>
                {vehicleVariantObject.map((vehicleVariant, index) => {
                  return (
                    <option value={vehicleVariant.MMCode} key={index}>
                      {vehicleVariant.Variant} - {vehicleVariant.MMCode}
                    </option>
                  );
                })}
                {vehicleVariantObject.length === 0 && (
                  <option value="NONE">NONE</option>
                )}
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Vehicle Identification Number (VIN) (Required)</Label>
              <Input
                type="text"
                name="vehicleVinNo"
                onChange={handleInfoChange}
                placeholder="VIN"
                required={true}
                value={data.vehicleVinNo}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Vehicle Engine Number (Required)</Label>
              <Input
                type="text"
                name="vehicleEngineNo"
                onChange={handleInfoChange}
                placeholder="Engine Number"
                required={true}
                value={data.vehicleEngineNo}
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Vehicle Type (Required)</Label>
              <Dropdown
                name="vehicleType"
                onChange={handleInfoChange}
                required={true}
                value={data.vehicleType}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Bakkie">Bakkie</option>
                <option value="Coupe">Coupe</option>
                <option value="Hatchback">Hatchback</option>
                <option value="Microbus">Microbus</option>
                <option value="Mini Bus">Mini Bus</option>
                <option value="Motorcycle">Motorcycle</option>
                <option value="Panel Van">Panel Van</option>
                <option value="Sedan">Sedan</option>
                <option value="Station Wagon">Station Wagon</option>
                <option value="SUV">SUV</option>
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Engine Capacity (Required)</Label>
              <Dropdown
                name="vehicleEngineCapacity"
                onChange={handleInfoChange}
                required={true}
                value={data.vehicleEngineCapacity}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="3 liter +">3 liter +</option>
                <option value="2 liter">2 liter</option>
                <option value="1.6 liter">1.6 liter</option>
                <option value="1 liter">1 liter</option>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>What will the vehicle be used for? (Required)</Label>
              <Dropdown
                name="vehicleUse"
                onChange={handleInfoChange}
                required={true}
                value={data.vehicleUse}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Events">Events</option>
                <option value="General">General</option>
                <option value="School">School</option>
                <option value="Travelling">Travelling</option>
                <option value="Work">Work</option>
              </Dropdown>
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>
                How many times do you drive to work per week? (Required)
              </Label>
              <Dropdown
                name="vehicleDriveToWorkPerWeek"
                onChange={handleInfoChange}
                required={true}
                value={data.vehicleDriveToWorkPerWeek}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="5">5</option>
                <option value="4">4</option>
                <option value="3">3</option>
                <option value="2">2</option>
                <option value="1">1</option>
                <option value="Varies">Varies</option>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Vehicle Registration Number (Required)</Label>
              <Input
                type="text"
                name="vehicleRegNo"
                onChange={handleInfoChange}
                placeholder="Registration Number"
                required={true}
                value={data.vehicleRegNo}
                width="100%"
              />
            </Col>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Vehicle Estimated Retail Value (Required)</Label>
              <Input
                type="number"
                min="0"
                step="any"
                onWheel={(e) => e.target.blur()}
                name="vehicleEstimatedValue"
                onChange={handleInfoChange}
                placeholder="Value"
                required={true}
                value={data.vehicleEstimatedValue}
                width="100%"
              />
            </Col>
          </Row>
        </ItemWrapper>
        <ItemWrapper>
          <Label>
            Are there any Anti-Theft or Recovery Systems in the vehicle?
            (Required)
          </Label>
          <ItemInnerWrapper>
            <RadioButton
              name="vehicleAntiTheftInstalled"
              id="Yes"
              onChange={handleInfoChange}
              value="Yes"
              checked={data.vehicleAntiTheftInstalled === "Yes"}
              label="Yes"
            />
            <RadioButton
              onChange={handleInfoChange}
              name="vehicleAntiTheftInstalled"
              id="No"
              value="No"
              checked={data.vehicleAntiTheftInstalled === "No"}
              label="No"
            />
          </ItemInnerWrapper>
          {antiTheftInstalledError && (
            <ValidationMessage>{antiTheftInstalledError}</ValidationMessage>
          )}
          {data.vehicleAntiTheftInstalled === "Yes" && (
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Anti-Theft / Vehicle Recovery Details (Required)</Label>
                <Dropdown
                  name="vehicleAntiTheftDetails"
                  onChange={handleInfoChange}
                  required={true}
                  value={data.vehicleAntiTheftDetails}
                  width="100%"
                >
                  <option value="" disabled hidden>
                    Please select
                  </option>
                  <option value="Tracker">Tracker</option>
                  <option value="Netstar">Netstar</option>
                  <option value="Matrix">Matrix</option>
                  <option value="Beame">Beame</option>
                  <option value="Other">Other</option>
                </Dropdown>
              </Col>
            </Row>
          )}
        </ItemWrapper>
        <ItemWrapper>
          <Label>
            Are there any modifications made to the vehicle? (Required)
          </Label>
          <ItemInnerWrapper>
            <RadioButton
              name="vehicleModifications"
              id="Yes"
              onChange={handleInfoChange}
              value="Yes"
              checked={data.vehicleModifications === "Yes"}
              label="Yes"
            />
            <RadioButton
              onChange={handleInfoChange}
              name="vehicleModifications"
              id="No"
              value="No"
              checked={data.vehicleModifications === "No"}
              label="No"
            />
          </ItemInnerWrapper>
          {modificationsError && (
            <ValidationMessage>{modificationsError}</ValidationMessage>
          )}
          {data.vehicleModifications === "Yes" && (
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Modifications on Vehicle Details (Required)</Label>
                <Dropdown
                  name="vehicleModificationsDetails"
                  onChange={handleInfoChange}
                  required={true}
                  value={data.vehicleModificationsDetails}
                  width="100%"
                >
                  <option value="" disabled hidden>
                    Please select
                  </option>
                  <option value="Aftermarket wheels">Aftermarket wheels</option>
                  <option value="Body kit">Body kit</option>
                  <option value="Engine upgrades">Engine upgrades</option>
                  <option value="Exhaust">Exhaust</option>
                  <option value="Lowered suspension">Lowered suspension</option>
                  <option value="Nitrous oxide">Nitrous oxide</option>
                  <option value="Other">Other</option>
                </Dropdown>
              </Col>
            </Row>
          )}
        </ItemWrapper>

        <ItemWrapper>
          <Label>Is the vehicle fitted with an immobiliser? (Required)</Label>
          <ItemInnerWrapper>
            <RadioButton
              name="vehicleImmobiliser"
              id="Yes"
              onChange={handleInfoChange}
              value="Yes"
              checked={data.vehicleImmobiliser === "Yes"}
              label="Yes"
            />
            <RadioButton
              onChange={handleInfoChange}
              name="vehicleImmobiliser"
              id="No"
              value="No"
              checked={data.vehicleImmobiliser === "No"}
              label="No"
            />
          </ItemInnerWrapper>
          {immobiliserError && (
            <ValidationMessage>{immobiliserError}</ValidationMessage>
          )}
        </ItemWrapper>
        <ItemWrapper>
          <Label>Is the vehicle registered as Code 3? (Required)</Label>
          <ItemInnerWrapper>
            <RadioButton
              name="vehicleRegisteredCode3"
              id="Yes"
              onChange={handleInfoChange}
              value="Yes"
              checked={data.vehicleRegisteredCode3 === "Yes"}
              label="Yes"
            />
            <RadioButton
              onChange={handleInfoChange}
              name="vehicleRegisteredCode3"
              id="No"
              value="No"
              checked={data.vehicleRegisteredCode3 === "No"}
              label="No"
            />
          </ItemInnerWrapper>
          {code3Error && <ValidationMessage>{code3Error}</ValidationMessage>}
        </ItemWrapper>
      </FormBody>
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
