import React, { useContext, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

//Context
import MyContext from "../../context/MyContext";

//Styled Components
import { Input } from "../styles/Input.styled";

const GoogleAddress = (props) => {
  const [address, setAddress] = useState("");
  const { setData } = useContext(MyContext);

  const searchOptions = {
    componentRestrictions: { country: "za" },
  };

  const handleAddressChange = (address) => {
    setAddress(address);
  };

  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);

      // console.log("Coordinates:", latLng);
      let streetNumber = "";
      let streetName = "";
      let suburb = "";
      let city = "";
      let province = "";
      let postalCode = "";

      results[0].address_components.forEach((item) => {
        item.types.forEach((type, index) => {
          switch (type) {
            case "street_number":
              streetNumber = item.long_name;
              break;

            case "route":
              streetName = item.long_name;
              break;

            case "sublocality":
              suburb = item.long_name;
              break;

            case "locality":
              city = item.long_name;
              break;

            case "administrative_area_level_1":
              province = item.long_name;
              break;

            case "postal_code":
              postalCode = item.long_name;
              break;

            default:
              break;
          }
        });
      });

      if (props.location === "insured") {
        setData((prevValue) => {
          return {
            ...prevValue,
            streetAddress: `${streetNumber} ${streetName}`,
            suburb: suburb,
            city: city,
            province: province,
            postalCode: postalCode,
          };
        });
      }

      if (props.location === "risk") {
        setData((prevValue) => {
          return {
            ...prevValue,
            riskStreetAddress: `${streetNumber} ${streetName}`,
            riskSuburb: suburb,
            riskCity: city,
            riskProvince: province,
            riskPostalCode: postalCode,
          };
        });
      }

      if (props.location === "day") {
        setData((prevValue) => {
          return {
            ...prevValue,
            dayStreetAddress: `${streetNumber} ${streetName}`,
            daySuburb: suburb,
            dayCity: city,
            dayProvince: province,
            dayPostalCode: postalCode,
          };
        });
      }

      if (props.location === "night") {
        setData((prevValue) => {
          return {
            ...prevValue,
            nightStreetAddress: `${streetNumber} ${streetName}`,
            nightSuburb: suburb,
            nightCity: city,
            nightProvince: province,
            nightPostalCode: postalCode,
          };
        });
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleAddressChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <div style={{ padding: "0 1% 0 0" }}>
            <Input
              width="100%"
              {...getInputProps({
                placeholder: "Search Places...",
                className: "location-search-input",
              })}
            />
          </div>

          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              return (
                <div
                  key={index}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                  })}
                >
                  <span key={index}>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GoogleAddress;
