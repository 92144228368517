const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const theme = {
  colors: {
    primary: "#143965",
    primaryFaded: "##95bcea",
    secondary: "#3b94bc",
    secondaryFaded: "##9ecce1",
    body: "#000",
    background: "#eee",
  },
  device: {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
  },
  deviceMax: {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`,
  },
  elevations: {
    small: `
        0.5px 1px 1px hsl(var(--shadow-color) / 0.7)
      `,
    medium: `
        1px 2px 2px hsl(var(--shadow-color) / 0.333),
        2px 4px 4px hsl(var(--shadow-color) / 0.333),
        3px 6px 6px hsl(var(--shadow-color) / 0.333)
      `,
    large: `
        1px 2px 2px hsl(var(--shadow-color) / 0.2),
        2px 4px 4px hsl(var(--shadow-color) / 0.2),
        4px 8px 8px hsl(var(--shadow-color) / 0.2),
        8px 16px 16px hsl(var(--shadow-color) / 0.2),
        16px 32px 32px hsl(var(--shadow-color) / 0.2)
      `,
  },
  font: {
    menu: "Montserrat, sans-serif",
    body: "Open Sans, sans-serif",
  },
  size: {
    headerHeight: "80px",
    mobileOn: "820px",
    mobileOff: "821px",
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "425px",
    tablet: "768px",
    laptop: "1024px",
    laptopL: "1440px",
    desktop: "2560px",
  },
  space: [
    "0.25rem",
    "0.5rem",
    "0.75rem",
    "1rem",
    "1.5rem",
    "2rem",
    "3rem",
    "4rem",
    "6rem",
    "8rem",
    "12rem",
    "16rem",
    "24rem",
  ],
};
