//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../../context/MyContext";

//Styled Components
import { Col } from "../../styles/Col";
import { Dropdown } from "../../styles/Dropdown.styled";
import { Form } from "../../styles/Form.styled";
import { Input } from "../../styles/Input.styled";
import { Row } from "../../styles/Row";
import RadioButton from "../../styles/RadioButton";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormDescription = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormLabel = styled.label`
  padding: ${(props) => props.theme.space[1]};
  font-size: medium;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.primary};
`;

const ItemInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
  color: red;
`;

export default function RegisteredOwner(props) {
  const {
    data,
    setData,
    setVehicleDataCaptured,
    setRegisteredOwnerDataCaptured,

    //Errors
    altNumberError,
    setAltNumberError,
    formError,
    setFormError,
    idNumberError,
    setIdNumberError,
    mobileError,
    setMobileError,
    selectError,
    setSelectError,
    workNumberError,
    setWorkNumberError,
  } = useContext(MyContext);

  const currentDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => setVehicleDataCaptured(false);

  const calculateAge = (birthDate, otherDate) => {
    birthDate = new Date(birthDate);
    otherDate = new Date(otherDate);
    var years = otherDate.getFullYear() - birthDate.getFullYear();
    if (
      otherDate.getMonth() < birthDate.getMonth() ||
      (otherDate.getMonth() === birthDate.getMonth() &&
        otherDate.getDate() < birthDate.getDate())
    ) {
      years--;
    }
    return years;
  };

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    // Create DOB from ID Number
    if (
      data.registeredOwnerIdType === "ID Document" &&
      name === "registeredOwnerId" &&
      value.length === 13 &&
      !data.registeredOwnerDob
    ) {
      const century = currentDate.toString().substring(0, 2);
      const year = currentDate.toString().substring(2, 4);
      const idYear = value.substring(0, 2);
      let dateString = "";

      if (idYear >= 0 && idYear <= year) {
        dateString = `${century}${value.substring(0, 2)}-${value.substring(
          2,
          4
        )}-${value.substring(4, 6)}`;
      } else {
        dateString = `${century - 1}${value.substring(0, 2)}-${value.substring(
          2,
          4
        )}-${value.substring(4, 6)}`;
      }

      const calculatedDOB = new Date(dateString).toISOString().split("T")[0];

      setData((prevValue) => {
        return {
          ...prevValue,
          registeredOwnerDob: calculatedDOB,
        };
      });
    }

    if (name === "registeredOwnerSame" && value === "Yes") {
      setData((prevValue) => {
        return {
          ...prevValue,
          registeredOwnerTitle: data.title,
          registeredOwnerFirstName: data.firstName,
          registeredOwnerLastName: data.lastName,
          registeredOwnerIdType: data.idType,
          registeredOwnerId: data.idNumber,
          registeredOwnerOccupation: data.occupation,
          registeredOwnerGender: data.gender,
          registeredOwnerDob: data.dob,
          registeredOwnerAge: data.age,
          registeredOwnerMaritalStatus: data.maritalStatus,
          registeredOwnerDriversLicenceCode: data.insuredDriversLicenceCode,
          registeredOwnerMobile: data.mobile,
          registeredOwnerWorkTelNo: data.workTelNo,
          registeredOwnerAltTelNo: data.altTelNo,
          registeredOwnerEmail: data.email,
        };
      });
    }

    if (name === "registeredOwnerSame" && value === "No") {
      setData((prevValue) => {
        return {
          ...prevValue,
          registeredOwnerTitle: "",
          registeredOwnerFirstName: "",
          registeredOwnerLastName: "",
          registeredOwnerIdType: "",
          registeredOwnerId: "",
          registeredOwnerOccupation: "",
          registeredOwnerGender: "",
          registeredOwnerDob: "",
          registeredOwnerAge: "",
          registeredOwnerMaritalStatus: "",
          registeredOwnerDriversLicenceCode: "",
          registeredOwnerMobile: "",
          registeredOwnerWorkTelNo: "",
          registeredOwnerAltTelNo: "",
          registeredOwnerEmail: "",
        };
      });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    // Registered Owner the Insured Not Selected
    if (data.registeredOwnerSame === "") {
      setSelectError("Please select an option");
      isValid = false;
    } else {
      setSelectError("");
      setFormError("");
    }

    // ID number validation
    if (data.registeredOwnerId.length !== 13 || isNaN(data.registeredOwnerId)) {
      setIdNumberError(
        "Please enter a valid 13-digit ID number without any spaces"
      );
      isValid = false;
    } else {
      setIdNumberError("");
      setFormError("");
    }

    // Mobile number validation
    if (
      data.registeredOwnerMobile.length !== 10 ||
      isNaN(data.registeredOwnerMobile)
    ) {
      setMobileError(
        "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
      );
      isValid = false;
    } else {
      setMobileError("");
      setFormError("");
    }

    // Work number validation
    if (data.registeredOwnerWorkTelNo !== "") {
      if (
        data.registeredOwnerWorkTelNo.length !== 10 ||
        isNaN(data.registeredOwnerWorkTelNo)
      ) {
        setWorkNumberError(
          "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
        );
        isValid = false;
      } else {
        setWorkNumberError("");
        setFormError("");
      }
    }

    // Alt number validation
    if (data.registeredOwnerAltTelNo !== "") {
      if (
        data.registeredOwnerAltTelNo.length !== 10 ||
        isNaN(data.registeredOwnerAltTelNo)
      ) {
        setAltNumberError(
          "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
        );
        isValid = false;
      } else {
        setAltNumberError("");
        setFormError("");
      }
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      const calculatedAge = calculateAge(data.registeredOwnerDob, currentDate);

      setData((prevValue) => {
        return {
          ...prevValue,
          registeredOwnerAge: calculatedAge,
        };
      });

      setRegisteredOwnerDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormSubHeading>Registered Owner Details</FormSubHeading>
      <FormDescription>
        Please complete the registered owner information below
      </FormDescription>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <ItemWrapper>
          <Label>Is the Registered Owner the Insured? (Required)</Label>
          <ItemInnerWrapper>
            <RadioButton
              name="registeredOwnerSame"
              id="Yes"
              onChange={handleInfoChange}
              value="Yes"
              checked={data.registeredOwnerSame === "Yes"}
              label="Yes"
              required={true}
            />
            <RadioButton
              name="registeredOwnerSame"
              id="No"
              onChange={handleInfoChange}
              value="No"
              checked={data.registeredOwnerSame === "No"}
              label="No"
              required={true}
            />
          </ItemInnerWrapper>
          {selectError && <ValidationMessage>{selectError}</ValidationMessage>}
        </ItemWrapper>

        {data.registeredOwnerSame === "No" && (
          <ItemWrapper>
            <FormLabel>Personal Information</FormLabel>
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Title</Label>
                <Dropdown
                  name="registeredOwnerTitle"
                  onChange={handleInfoChange}
                  required={false}
                  value={data.registeredOwnerTitle}
                  width="100%"
                >
                  <option value="" disabled hidden>
                    Please select
                  </option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr">Dr</option>
                  <option value="Prof">Prof</option>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>First Name (Required)</Label>
                <Input
                  type="text"
                  name="registeredOwnerFirstName"
                  onChange={handleInfoChange}
                  placeholder=""
                  required={true}
                  value={data.registeredOwnerFirstName}
                  width="100%"
                />
              </Col>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Last Name (Required)</Label>
                <Input
                  type="text"
                  name="registeredOwnerLastName"
                  onChange={handleInfoChange}
                  placeholder=""
                  required={true}
                  value={data.registeredOwnerLastName}
                  width="100%"
                />
              </Col>
            </Row>
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Identity Type (Required)</Label>
                <Dropdown
                  name="registeredOwnerIdType"
                  onChange={handleInfoChange}
                  required={true}
                  value={data.registeredOwnerIdType}
                  width="100%"
                >
                  <option value="" disabled hidden>
                    Please select
                  </option>
                  <option value="ID Document">ID Document</option>
                  <option value="Passport">Passport</option>
                </Dropdown>
              </Col>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>ID Number (Required)</Label>
                <Input
                  type="text"
                  name="registeredOwnerId"
                  onChange={handleInfoChange}
                  placeholder=""
                  required={true}
                  value={data.registeredOwnerId}
                  width="100%"
                  maxLength={13}
                />
                {idNumberError && (
                  <ValidationMessage>{idNumberError}</ValidationMessage>
                )}
              </Col>
            </Row>
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Mobile Telephone Number (Required)</Label>
                <Input
                  type="text"
                  name="registeredOwnerMobile"
                  onChange={handleInfoChange}
                  placeholder=""
                  required={true}
                  value={data.registeredOwnerMobile}
                  width="100%"
                  maxLength={10}
                />
                {mobileError && (
                  <ValidationMessage>{mobileError}</ValidationMessage>
                )}
              </Col>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Work Telephone Number</Label>
                <Input
                  type="text"
                  name="registeredOwnerWorkTelNo"
                  onChange={handleInfoChange}
                  placeholder=""
                  required={false}
                  value={data.registeredOwnerWorkTelNo}
                  width="100%"
                  maxLength={10}
                />
                {workNumberError && (
                  <ValidationMessage>{workNumberError}</ValidationMessage>
                )}
              </Col>
            </Row>
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Alternative Telephone Number</Label>
                <Input
                  type="text"
                  name="registeredOwnerAltTelNo"
                  onChange={handleInfoChange}
                  placeholder=""
                  required={false}
                  value={data.registeredOwnerAltTelNo}
                  width="100%"
                  maxLength={10}
                />
                {altNumberError && (
                  <ValidationMessage>{altNumberError}</ValidationMessage>
                )}
              </Col>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Email (Required)</Label>
                <Input
                  type="email"
                  name="registeredOwnerEmail"
                  onChange={handleInfoChange}
                  placeholder=""
                  required={true}
                  value={data.registeredOwnerEmail}
                  width="100%"
                />
              </Col>
            </Row>
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Occupation (Required)</Label>
                <Dropdown
                  name="registeredOwnerOccupation"
                  onChange={handleInfoChange}
                  required={true}
                  value={data.registeredOwnerOccupation}
                  width="100%"
                >
                  <option value="" disabled hidden>
                    Please select
                  </option>
                  <option value="Partially Skilled">Partially Skilled</option>
                  <option value="Skilled">Skilled</option>
                  <option value="Unskilled">Unskilled</option>
                  <option value="Business Owner">Business Owner</option>
                  <option value="Professional">Professional</option>
                </Dropdown>
              </Col>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Gender (Required)</Label>
                <Dropdown
                  name="registeredOwnerGender"
                  onChange={handleInfoChange}
                  required={true}
                  value={data.registeredOwnerGender}
                  width="100%"
                >
                  <option value="" disabled hidden>
                    Please select
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Date of Birth (Required)</Label>
                <Input
                  type="text"
                  name="registeredOwnerDob"
                  onChange={handleInfoChange}
                  placeholder=""
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  required={true}
                  value={data.registeredOwnerDob}
                  width="100%"
                />
              </Col>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Marital Status (Required)</Label>
                <Dropdown
                  name="registeredOwnerMaritalStatus"
                  onChange={handleInfoChange}
                  required={true}
                  value={data.registeredOwnerMaritalStatus}
                  width="100%"
                >
                  <option value="" disabled hidden>
                    Please select
                  </option>
                  <option value="Single">Single</option>
                  <option value="In a relationship">In a relationship</option>
                  <option value="Married">Married</option>
                </Dropdown>
              </Col>
            </Row>
            <Col $md={6} style={{ padding: "0 1% 0 0" }}>
              <Label>Driver's Licence Code (Required)</Label>
              <Dropdown
                name="registeredOwnerDriversLicenceCode"
                onChange={handleInfoChange}
                required={true}
                value={data.registeredOwnerDriversLicenceCode}
                width="100%"
              >
                <option value="" disabled hidden>
                  Please select
                </option>
                <option value="Code 8">Code 8</option>
                <option value="Code 10">Code 10</option>
                <option value="Code 14">Code 14</option>
                <option value="Advanced Driving">Advanced Driving</option>
              </Dropdown>
            </Col>
          </ItemWrapper>
        )}
      </FormBody>
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
