import { React, useContext } from "react";
import axios from "axios";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Styled Components
import { Form } from "../styles/Form.styled";
import { Input } from "../styles/Input.styled";

const ButtonWrapper = styled.div`
  display: flex;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  align-items: center;
  justify-content: center;
`;

const FormHeading = styled.div`
  text-align: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
  font-family: ${(props) => props.theme.font.legal};
`;

const FormSubHeadingCentre = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
  text-align: center;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Authentication(props) {
  const {
    data,
    setData,
    dataFetched,
    setDataFetched,
    headers,
    idVerification,
    setIdVerification,
    idVerificationFailed,
    setIdVerificationFailed,
  } = useContext(MyContext);

  const GetLead = (email) => {
    const leadData = {
      leadId: data.leadId,
      email: email,
    };
    if (headers && !dataFetched && data.leadId) {
      setDataFetched(true);
      axios
        // Enable this for local testing environment
        // .post("http://localhost:5000/api/get-lead", leadData, {
        // Enable this for hosted production environment
        .post("api/get-lead", leadData, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response: " + response.status);
              setIdVerification(true);
              SaveLead(response);
            } else {
              setIdVerificationFailed(true);
              console.log("Laminin Response: " + response.status);
            }
          },
          (error) => {
            setIdVerificationFailed(true);
            console.log("Laminin Response Error: " + error);
          }
        );
    }
  };

  const SaveLead = (response) => {
    setData((prevValue) => {
      return {
        ...prevValue,
        //Insured Data
        title: response.data.Fields.Title,
        firstName: response.data.Fields.FirstName,
        lastName: response.data.Fields.LastName,
        idType: response.data.Fields.IDType,
        idNumber: response.data.Fields.IDNumber,
        occupation: response.data.Fields.Occupation,
        gender: response.data.Fields.Gender,
        dob: response.data.Fields.DateOfBirth,
        age: response.data.Fields.Age,
        maritalStatus: response.data.Fields.MaritalStatus,
        insuredDriversLicenceCode: response.data.Fields.DriversLicenseCode,
        mobile: response.data.Fields.Mobile,
        workTelNo: response.data.Fields.WorkNumber,
        altTelNo: response.data.Fields.AlternativeNumber,
        email: response.data.Fields.Email,

        //Insured Street Address
        addressType: response.data.Fields.AddressType,
        complex: response.data.Fields.Complex,
        streetAddress: response.data.Fields.StreetAddress,
        suburb: response.data.Fields.Suburb,
        city: response.data.Fields.City,
        province: response.data.Fields.Province,
        postalCode: response.data.Fields.PostalCode,

        //Insured Postal Address
        postalComplex: response.data.Fields.PostalComplex,
        postalAddress: response.data.Fields.PostalAddress,
        postalSuburb: response.data.Fields.PostalSuburb,
        postalCity: response.data.Fields.PostalCity,
        postalProvince: response.data.Fields.PostalProvince,
        postalPostalCode: response.data.Fields.PostalPostalCode,

        //Insurance Data
        currentlyInsured: response.data.Fields.CurrentlyInsured,
        insurerName: response.data.Fields.InsurerName,
        insurerDuration: response.data.Fields.InsurerDuration,
        insuranceAmount: response.data.Fields.InsuranceAmount,
        insuranceUninterruptedPast36Months:
          response.data.Fields.InsurancePast36Months,
        insuranceClaimsLast12Months: response.data.Fields.ClaimsLast12Months,
        insuranceClaimsLast24Months: response.data.Fields.ClaimsLast24Months,
        insuranceClaimsLast36Months: response.data.Fields.ClaimsLast36Months,
        carHireRequired: response.data.Fields.CarHireRequired,
        carHireType: response.data.Fields.CarHireType,

        //Insured Vehicle Data
        vehicleYear: response.data.Fields.vehicleYear,
        vehicleAge: response.data.Fields.vehicleAge,
        vehicleMake: response.data.Fields.vehicleMake,
        vehicleModel: response.data.Fields.vehicleModel,
        vehicleVinNo: response.data.Fields.vehicleVinNo,
        vehicleEngineNo: response.data.Fields.vehicleEngineNo,
        vehicleRegNo: response.data.Fields.vehicleRegNo,
        vehicleValue: response.data.Fields.vehicleValue,
        vehicleUse: response.data.Fields.vehicleUse,
        vehicleDriveToWorkPerWeek:
          response.data.Fields.vehicleDriveToWorkPerWeek,
        vehicleType: response.data.Fields.vehicleType,
        vehicleEngineCapacity: response.data.Fields.vehicleEngineCapacity,
        vehicleAntiTheftInstalled:
          response.data.Fields.vehicleAntiTheftInstalled,
        vehicleAntiTheftDetails: response.data.Fields.vehicleAntiTheftDetails,
        vehicleModifications: response.data.Fields.vehicleModifications,
        vehicleModificationsDetails:
          response.data.Fields.vehicleModificationsDetails,
        vehicleImmobiliser: response.data.Fields.vehicleImmobiliser,
        vehicleRegisteredCode3: response.data.Fields.vehicleRegisteredCode3,
        insuranceSub: response.data.Fields.insuranceSub,
        insuranceClaimSub20: response.data.Fields.insuranceClaimSub20,
        insuranceClaimSub40: response.data.Fields.insuranceClaimSub40,
        insuranceClaimSub60: response.data.Fields.insuranceClaimSub60,
        insuranceClaimSub80: response.data.Fields.insuranceClaimSub80,
        insuranceClaimSub100: response.data.Fields.insuranceClaimSub100,
        insuranceClaimTotal20: response.data.Fields.insuranceClaimTotal20,
        insuranceClaimTotal40: response.data.Fields.insuranceClaimTotal40,
        insuranceClaimTotal60: response.data.Fields.insuranceClaimTotal60,
        insuranceClaimTotal80: response.data.Fields.insuranceClaimTotal80,
        insuranceClaimTotal100: response.data.Fields.insuranceClaimTotal100,

        //Insured Vehicle Risk Address
        riskAddressSame: response.data.Fields.riskAddressSame,
        riskAddressType: response.data.Fields.riskAddressType,
        riskComplex: response.data.Fields.riskComplex,
        riskStreetAddress: response.data.Fields.riskStreetAddress,
        riskSuburb: response.data.Fields.riskSuburb,
        riskCity: response.data.Fields.riskCity,
        riskProvince: response.data.Fields.riskProvince,
        riskPostalCode: response.data.Fields.riskPostalCode,

        //Insured Vehicle Day Address
        dayRiskAddressSame: response.data.Fields.dayRiskAddressSame,
        dayAddressType: response.data.Fields.dayAddressType,
        dayAddressSecure: response.data.Fields.dayAddressSecure,
        dayComplex: response.data.Fields.dayComplex,
        dayStreetAddress: response.data.Fields.dayStreetAddress,
        daySuburb: response.data.Fields.daySuburb,
        dayCity: response.data.Fields.dayCity,
        dayProvince: response.data.Fields.dayProvince,
        dayPostalCode: response.data.Fields.dayPostalCode,

        //Insured Vehicle Night Address
        nightRiskAddressSame: response.data.Fields.nightRiskAddressSame,
        nightAddressType: response.data.Fields.nightAddressType,
        nightAddressSecure: response.data.Fields.nightAddressSecure,
        nightComplex: response.data.Fields.nightComplex,
        nightStreetAddress: response.data.Fields.nightStreetAddress,
        nightSuburb: response.data.Fields.nightSuburb,
        nightCity: response.data.Fields.nightCity,
        nightProvince: response.data.Fields.nightProvince,
        nightPostalCode: response.data.Fields.nightPostalCode,

        //Insured Vehicle Finance Data
        isVehicleFinanced: response.data.Fields.isVehicleFinanced,
        financeCompany: response.data.Fields.financeCompany,
        financeAgreement: response.data.Fields.financeAgreement,
        financeAccountNo: response.data.Fields.financeAccountNo,
        financeBranch: response.data.Fields.financeBranch,
        financeOutstanding: response.data.Fields.financeOutstanding,
        creditShortfallExtension: response.data.Fields.creditShortfallExtension,

        //Insured Vehicle Regular Driver
        regularDriverSame: response.data.Fields.regularDriverSame,
        regularDriverTitle: response.data.Fields.regularDriverTitle,
        regularDriverFirstName: response.data.Fields.regularDriverFirstName,
        regularDriverLastName: response.data.Fields.regularDriverLastName,
        regularDriverIdType: response.data.Fields.regularDriverIdType,
        regularDriverId: response.data.Fields.regularDriverId,
        regularDriverOccupation: response.data.Fields.regularDriverOccupation,
        regularDriverGender: response.data.Fields.regularDriverGender,
        regularDriverDob: response.data.Fields.regularDriverDob,
        regularDriverAge: response.data.Fields.regularDriverAge,
        regularDriverMaritalStatus:
          response.data.Fields.regularDriverMaritalStatus,
        regularDriverDriversLicenceCode:
          response.data.Fields.regDriverDriversLicenceCode,
        regularDriverMobile: response.data.Fields.regularDriverMobile,
        regularDriverWorkTelNo: response.data.Fields.regularDriverWorkTelNo,
        regularDriverAltTelNo: response.data.Fields.regularDriverAltTelNo,
        regularDriverEmail: response.data.Fields.regularDriverEmail,

        //Registered Owner
        registeredOwnerSame: response.data.Fields.registeredOwnerSame,
        registeredOwnerTitle: response.data.Fields.registeredOwnerTitle,
        registeredOwnerFirstName: response.data.Fields.registeredOwnerFirstName,
        registeredOwnerLastName: response.data.Fields.registeredOwnerLastName,
        registeredOwnerIdType: response.data.Fields.registeredOwnerIdType,
        registeredOwnerId: response.data.Fields.registeredOwnerId,
        registeredOwnerOccupation:
          response.data.Fields.registeredOwnerOccupation,
        registeredOwnerGender: response.data.Fields.registeredOwnerGender,
        registeredOwnerDob: response.data.Fields.registeredOwnerDob,
        registeredOwnerAge: response.data.Fields.registeredOwnerAge,
        registeredOwnerMaritalStatus:
          response.data.Fields.registeredOwnerMaritalStatus,
        registeredOwnerDriversLicenceCode:
          response.data.Fields.regOwnerDriversLicenceCode,
        registeredOwnerMobile: response.data.Fields.registeredOwnerMobile,
        registeredOwnerWorkTelNo: response.data.Fields.registeredOwnerWorkTelNo,
        registeredOwnerAltTelNo: response.data.Fields.registeredOwnerAltTelNo,
        registeredOwnerEmail: response.data.Fields.registeredOwnerEmail,
      };
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    GetLead(data.otp);
  };

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  return (
    <div>
      {!idVerification && !idVerificationFailed && (
        <Form onSubmit={handleFormSubmit}>
          <FormHeading>AUTHENTICATION</FormHeading>
          <FormSubHeadingCentre>
            Please enter the Client's Email Address and click on Submit
          </FormSubHeadingCentre>
          <InputWrapper>
            <Input
              type="text"
              name="otp"
              style={{ textAlign: "center" }}
              onChange={handleInfoChange}
              onWheel={(e) => e.target.blur()}
              placeholder=""
              required={true}
              value={data.otp}
              width="50%"
              modwidth="50%"
            />
          </InputWrapper>
          <ButtonWrapper>
            <button
              className="btn btn-dark btn-md"
              type="submit"
              style={{ margin: "0 2px" }}
            >
              Submit
            </button>
          </ButtonWrapper>
        </Form>
      )}

      {idVerificationFailed && (
        <Form>
          <FormHeading>AUTHENTICATION FAILED</FormHeading>
          <FormSubHeadingCentre>
            Could not validate your ID number. We apologise for the
            inconvenience. Please contact us on 031 109 1377.
          </FormSubHeadingCentre>
        </Form>
      )}
    </div>
  );
}
