//NPM Packages
import { React, useContext, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Components
import DayAddress from "../CarSubscription/Vehicles/DayAddress";
import Finance from "../CarSubscription/Vehicles/Finance";
import NightAddress from "../CarSubscription/Vehicles/NightAddress";
import RegisteredOwner from "../CarSubscription/Vehicles/RegisteredOwner";
import RegularDriver from "../CarSubscription/Vehicles/RegularDriver";
import RiskAddress from "../CarSubscription/Vehicles/RiskAddress";
import Vehicle from "../CarSubscription/Vehicles/Vehicle";
import VehicleComponent from "./Vehicles/VehicleComponent";

//Styled Components
import { Form } from "../styles/Form.styled";
import VehicleSubComponent from "./Vehicles/VehicleSubComponent";
import VehicleSubClaimComponent from "./Vehicles/VehicleSubClaimComponent";
import VehicleTotalClaimComponent from "./Vehicles/VehicleTotalClaimComponent";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormDescription = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const TableLabel = styled.label`
  padding-bottom: ${(props) => props.theme.space[1]};
  font-size: medium;
  font-weight: bold;
  text-decoration: underline;
`;

const ValidationMessageCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
  color: red;
`;

export default function Vehicles(props) {
  const {
    data,
    setData,

    vehicles,
    setVehicles,

    formError,
    setFormError,
    vehiclesError,
    setVehiclesError,

    captureVehicle,
    setCaptureVehicle,
    setInsuranceDataCaptured,
    vehiclesDataCaptured,
    setVehiclesDataCaptured,

    //Laminin
    headers,
    lamininError,
    setLamininError,
    setLamininResponse,
    lamininSent,
    setLamininSent,
    lamininSubmitted,
    setLamininSubmitted,

    //Forms
    dayAddressDataCaptured,
    financeDataCaptured,
    registeredOwnerDataCaptured,
    regularDriverDataCaptured,
    riskAddressDataCaptured,
    vehicleDataCaptured,
  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => setInsuranceDataCaptured(false);

  const addVehicle = () => {
    setCaptureVehicle(true);
  };

  const deleteVehicle = (id) => {
    setVehicles((prevValue) => {
      return prevValue.filter((vehicles, index) => {
        return index !== id;
      });
    });
  };

  const saveLeadId = (leadId) => {
    setData((prevValue) => {
      return {
        ...prevValue,
        leadId: leadId,
      };
    });
  };

  const submitToLaminin = () => {
    setLamininSubmitted(true);

    // Enable this for local testing environment
    axios
      // .post("http://localhost:5000/api/new-sub/create", data, {
      // Enable this for hosted production environment
      .post("api/new-sub/create", data, {
        headers: headers,
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setLamininResponse(response.data);
            console.log("Laminin Response OK: " + response.status);
            setLamininSent(true);
            const { NewLeadId } = response.data;
            saveLeadId(NewLeadId);
          } else {
            console.log("Laminin Response Not OK: " + response.status);
            setLamininError(true);
          }
        },
        (error) => {
          console.log("Laminin Response Error: " + error);
          setLamininError(true);
        }
      );
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    //Vehicles validation
    if (vehicles.length === 0) {
      setVehiclesError("Please add at least one vehicle");
      isValid = false;
    } else {
      setVehiclesError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      setVehiclesDataCaptured(true);
      submitToLaminin();
    }
  };

  return (
    <div>
      {!captureVehicle && !vehiclesDataCaptured && (
        <Form onSubmit={handleFormSubmit}>
          <FormHeading>
            {props.name} - {props.formName}
          </FormHeading>
          <FormDescription>
            {vehicles.length === 0
              ? "Please add a vehicle to continue with the application"
              : "Please click on submit to submit the application"}
          </FormDescription>
          {formError && (
            <ValidationSubHeading>{formError}</ValidationSubHeading>
          )}
          <FormBody>
            <ButtonWrapper>
              <button
                onClick={addVehicle}
                className="btn btn-dark btn-md"
                type="button"
                style={{ margin: "0 2px" }}
                disabled={vehicles.length > 0}
              >
                Add Vehicle
              </button>
            </ButtonWrapper>

            {vehiclesError && (
              <ValidationMessageCenter>{vehiclesError}</ValidationMessageCenter>
            )}

            {vehicles.map((vehicle, index) => {
              return (
                <ItemWrapper key={index}>
                  <TableLabel>Insured Vehicle {index + 1} Details</TableLabel>
                  <VehicleComponent
                    id={index}
                    make={vehicle.vehicleMake}
                    model={vehicle.vehicleModel}
                    regNo={vehicle.vehicleRegNo}
                    value={vehicle.vehicleValue}
                    onDelete={deleteVehicle}
                  />
                  <hr />
                  <TableLabel>Monthly Insurance Subscription</TableLabel>
                  <VehicleSubComponent
                    id={index}
                    value={
                      vehicle.vehicleValue === 0
                        ? vehicle.vehicleEstimatedValue
                        : vehicle.vehicleValue
                    }
                    valueHeading={
                      vehicle.vehicleValue === 0
                        ? "Estimated Value"
                        : "Vehicle Value"
                    }
                    sub={vehicle.insuranceSub}
                  />
                  <hr />
                  <TableLabel>
                    Monthly Insurance Subscription Upon Claim
                  </TableLabel>
                  <VehicleSubClaimComponent
                    id={index}
                    value={vehicle.vehicleValue}
                    claimSub20={vehicle.insuranceClaimSub20}
                    claimSub40={vehicle.insuranceClaimSub40}
                    claimSub60={vehicle.insuranceClaimSub60}
                    claimSub80={vehicle.insuranceClaimSub80}
                    claimSub100={vehicle.insuranceClaimSub100}
                  />
                  <hr />
                  <TableLabel>
                    Total Insurance Subscription Payback Upon Claim
                  </TableLabel>
                  <VehicleTotalClaimComponent
                    id={index}
                    value={vehicle.vehicleValue}
                    totalSub20={vehicle.insuranceClaimTotal20}
                    totalSub40={vehicle.insuranceClaimTotal40}
                    totalSub60={vehicle.insuranceClaimTotal60}
                    totalSub80={vehicle.insuranceClaimTotal80}
                    totalSub100={vehicle.insuranceClaimTotal100}
                  />
                </ItemWrapper>
              );
            })}
          </FormBody>
          <ButtonWrapper>
            <button
              onClick={back}
              className="btn btn-outline-dark btn-md"
              type="button"
              style={{ margin: "0 2px" }}
            >
              Back
            </button>
            <button
              className="btn btn-dark btn-md"
              type="submit"
              style={{ margin: "0 2px" }}
              disabled={vehicles.length === 0}
            >
              Apply Now
            </button>
          </ButtonWrapper>
        </Form>
      )}

      {!vehicleDataCaptured && captureVehicle && (
        <Vehicle name="Price Insure" formName="Car Subscription Insurance" />
      )}

      {!registeredOwnerDataCaptured &&
        vehicleDataCaptured &&
        captureVehicle && (
          <RegisteredOwner
            name="Price Insure"
            formName="Car Subscription Insurance"
          />
        )}

      {!regularDriverDataCaptured &&
        registeredOwnerDataCaptured &&
        vehicleDataCaptured &&
        captureVehicle && (
          <RegularDriver
            name="Price Insure"
            formName="Car Subscription Insurance"
          />
        )}

      {!financeDataCaptured &&
        regularDriverDataCaptured &&
        registeredOwnerDataCaptured &&
        vehicleDataCaptured &&
        captureVehicle && (
          <Finance name="Price Insure" formName="Car Subscription Insurance" />
        )}

      {!riskAddressDataCaptured &&
        financeDataCaptured &&
        regularDriverDataCaptured &&
        registeredOwnerDataCaptured &&
        vehicleDataCaptured &&
        captureVehicle && (
          <RiskAddress
            name="Price Insure"
            formName="Car Subscription Insurance"
          />
        )}

      {!dayAddressDataCaptured &&
        riskAddressDataCaptured &&
        financeDataCaptured &&
        regularDriverDataCaptured &&
        registeredOwnerDataCaptured &&
        vehicleDataCaptured &&
        captureVehicle && (
          <DayAddress
            name="Price Insure"
            formName="Car Subscription Insurance"
          />
        )}

      {dayAddressDataCaptured &&
        riskAddressDataCaptured &&
        financeDataCaptured &&
        regularDriverDataCaptured &&
        registeredOwnerDataCaptured &&
        vehicleDataCaptured &&
        captureVehicle && (
          <NightAddress
            name="Price Insure"
            formName="Car Subscription Insurance"
          />
        )}

      {vehiclesDataCaptured &&
        lamininSubmitted &&
        !(lamininSent || lamininError) && (
          <Form>
            <FormHeading>
              {props.name} - {props.formName}
            </FormHeading>
            <FormSubHeading>Processing...</FormSubHeading>
            <FormSubHeading>
              Submitting your information, please stand by...
            </FormSubHeading>
          </Form>
        )}

      {vehiclesDataCaptured && lamininSent && (
        <Form>
          <FormHeading>
            {props.name} - {props.formName}
          </FormHeading>
          <FormSubHeading>Complete!</FormSubHeading>
          <FormSubHeading>
            Thank you for submitting your information, your Reference Number
            (Laminin Lead ID) is {data.leadId}. One of our representatives will
            be in contact with you shortly.
          </FormSubHeading>
        </Form>
      )}

      {vehiclesDataCaptured && lamininError && (
        <Form>
          <FormHeading>
            {props.name} - {props.formName}
          </FormHeading>
          <FormSubHeading>Something went wrong</FormSubHeading>
          <FormSubHeading>
            Your information was not received, we apologise for the
            inconvenience. Please contact us on {props.phone}.
          </FormSubHeading>
        </Form>
      )}
    </div>
  );
}
