import React from "react";

// Create a context with default values
const MyContext = React.createContext({
  //Authentication
  count: 0,
  dataFetched: false,
  getIp: false,
  headers: "",
  idVerification: false,
  idVerificationRequired: false,
  idVerificationFailed: false,
  requestAuthentication: false,

  //Data
  data: {},

  //Docs
  docsData: {},
  docsSubmitted: false,
  docsSent: false,
  docsError: false,
  docsResponse: "",

  //Errors
  acceptTermsError: "",
  acceptOneVapTermsError: "",
  addressSameError: "",
  addressSecureError: "",
  ageError: "",
  altNumberError: "",
  antiTheftInstalledError: "",
  code3Error: "",
  consentBureauInformationError: "",
  consentFraudIdentityCheckError: "",
  contactNumberError: "",
  formError: "",
  homeNumberError: "",
  idNumberError: "",
  immobiliserError: "",
  mobileError: "",
  modificationsError: "",
  postalCodeError: "",
  postalPostalCodeError: "",
  selectError: "",
  signatureError: "",
  underAdministrativeOrderError: "",
  underDebtReviewError: "",
  underSequestrationError: "",
  vehiclesError: "",
  vehicleYearError: "",
  witnessContactNumberError: "",
  workNumberError: "",

  //Laminin
  lamininSubmitted: false,
  lamininSent: false,
  lamininError: false,
  lamininResponse: "",

  //Mail
  mailSubmitted: false,
  mailSent: false,
  mailError: false,

  //Forms
  addressDataCaptured: false,
  captureVehicle: false,
  dayAddressDataCaptured: false,
  declarationDataCaptured: false,
  docsDataCaptured: false,
  financeDataCaptured: false,
  insuranceDataCaptured: false,
  insuredDataCaptured: false,
  nightAddressDataCaptured: false,
  quoteDataCaptured: false,
  registeredOwnerDataCaptured: false,
  riskAddressDataCaptured: false,
  vehicleDataCaptured: false,
  vehiclesDataCaptured: false,

  //Vehicles
  vehicles: [],
});

export default MyContext;
