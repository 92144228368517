//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../../context/MyContext";

//Data
import { ProvinceData } from "../../../data/ProvinceData";

//Styled Components
import { Col } from "../../styles/Col";
import { Dropdown } from "../../styles/Dropdown.styled";
import { Form } from "../../styles/Form.styled";
import GoogleAddress from "../../Shared/GoogleAddress";
import { Input } from "../../styles/Input.styled";
import RadioButton from "../../styles/RadioButton";
import { Row } from "../../styles/Row";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormDescription = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormLabel = styled.label`
  padding: ${(props) => props.theme.space[1]};
  font-size: medium;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.primary};
`;

const ItemInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
  color: red;
`;

export default function DayAddress(props) {
  const {
    data,
    setData,
    addressSameError,
    setAddressSameError,
    addressSecureError,
    setAddressSecureError,
    formError,
    setFormError,
    postalCodeError,
    setPostalCodeError,
    setRiskAddressDataCaptured,
    setDayAddressDataCaptured,
  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => setRiskAddressDataCaptured(false);

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    if (name === "dayRiskAddressSame" && value === "Yes") {
      setData((prevValue) => {
        return {
          ...prevValue,
          dayAddressType: data.addressType,
          dayComplex: data.complex,
          dayStreetAddress: data.streetAddress,
          daySuburb: data.suburb,
          dayCity: data.city,
          dayProvince: data.province,
          dayPostalCode: data.postalCode,
        };
      });
    }

    if (name === "dayRiskAddressSame" && value === "No") {
      setData((prevValue) => {
        return {
          ...prevValue,
          dayAddressType: "",
          dayComplex: "",
          dayStreetAddress: "",
          daySuburb: "",
          dayCity: "",
          dayProvince: "",
          dayPostalCode: "",
        };
      });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    // Completion checks
    if (data.dayAddressSecure === "") {
      setAddressSecureError("Please select an option");
      isValid = false;
    } else {
      setAddressSecureError("");
      setFormError("");
    }

    if (data.dayRiskAddressSame === "") {
      setAddressSameError("Please select an option");
      isValid = false;
    } else {
      setAddressSameError("");
      setFormError("");
    }

    // Postal code validation
    if (data.dayPostalCode.length !== 4 || isNaN(data.dayPostalCode)) {
      setPostalCodeError(
        "Please enter a valid 4-digit Postal Code without any spaces"
      );
      isValid = false;
    } else {
      setPostalCodeError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      setDayAddressDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormSubHeading>Vehicle Day Address</FormSubHeading>
      <FormDescription>
        Please complete the vehicle day address information below
      </FormDescription>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <ItemWrapper>
          <Label>
            Is the vehicle parked in a secure location during the day?
            (Required)
          </Label>
          <ItemInnerWrapper>
            <Dropdown
              name="dayAddressSecure"
              onChange={handleInfoChange}
              required={true}
              value={data.dayAddressSecure}
              width="100%"
            >
              <option value="" disabled hidden>
                Please select
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Varies">Varies</option>
            </Dropdown>
          </ItemInnerWrapper>
          {addressSecureError && (
            <ValidationSubHeading>{addressSecureError}</ValidationSubHeading>
          )}
        </ItemWrapper>
        <ItemWrapper>
          <Label>
            Is the Day Address the same as the Risk Address? (Required)
          </Label>
          <ItemInnerWrapper>
            <RadioButton
              name="dayRiskAddressSame"
              id="Yes"
              onChange={handleInfoChange}
              value="Yes"
              checked={data.dayRiskAddressSame === "Yes"}
              label="Yes"
              required={true}
            />
            <RadioButton
              name="dayRiskAddressSame"
              id="No"
              onChange={handleInfoChange}
              value="No"
              checked={data.dayRiskAddressSame === "No"}
              label="No"
              required={true}
            />
          </ItemInnerWrapper>
          {addressSameError && (
            <ValidationSubHeading>{addressSameError}</ValidationSubHeading>
          )}
        </ItemWrapper>

        {data.dayRiskAddressSame === "No" && (
          <div>
            <ItemWrapper>
              <FormLabel>Google Address Search</FormLabel>
              <GoogleAddress location="day" />
            </ItemWrapper>
            <ItemWrapper>
              <FormLabel>Physical Address Information</FormLabel>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Unit / Complex</Label>
                  <Input
                    type="text"
                    name="dayComplex"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={false}
                    value={data.dayComplex}
                    width="100%"
                  />
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Street Address (Required)</Label>
                  <Input
                    type="text"
                    name="dayStreetAddress"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.dayStreetAddress}
                    width="100%"
                  />
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Suburb (Required)</Label>
                  <Input
                    type="text"
                    name="daySuburb"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.daySuburb}
                    width="100%"
                  />
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>City (Required)</Label>
                  <Input
                    type="text"
                    name="dayCity"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.dayCity}
                    width="100%"
                  />
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Province (Required)</Label>
                  <Dropdown
                    name="dayProvince"
                    onChange={handleInfoChange}
                    required={true}
                    value={data.dayProvince}
                    width="100%"
                  >
                    <option value="" disabled hidden>
                      Province
                    </option>
                    {ProvinceData.map((province, index) => {
                      return (
                        <option value={province.name} key={index}>
                          {province.name}
                        </option>
                      );
                    })}
                  </Dropdown>
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Postal Code (Required)</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    onWheel={(e) => e.target.blur()}
                    name="dayPostalCode"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.dayPostalCode}
                    width="100%"
                  />
                  {postalCodeError && (
                    <ValidationMessage>{postalCodeError}</ValidationMessage>
                  )}
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Type of Residence (Required)</Label>
                  <Dropdown
                    name="dayAddressType"
                    onChange={handleInfoChange}
                    required={true}
                    value={data.dayAddressType}
                    width="100%"
                  >
                    <option value="" disabled hidden>
                      Please select
                    </option>
                    <option value="City">City</option>
                    <option value="Country Estate">Country Estate</option>
                    <option value="Estate">Estate</option>
                    <option value="Township Area">Township Area</option>
                    <option value="Urban">Urban</option>
                  </Dropdown>
                </Col>
              </Row>
            </ItemWrapper>
          </div>
        )}
      </FormBody>
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
