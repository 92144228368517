//NPM Packages
import { React, useContext } from "react";
import styled from "styled-components";

//Context
import MyContext from "../context/MyContext";

//Components
import Authentication from "../components/Shared/Authentication";
import Insurance from "../components/CarSubscription/Insurance";
import Insured from "../components/CarSubscription/Insured";
import Address from "../components/CarSubscription/Address";
import Vehicles from "../components/CarSubscription/Vehicles";

import Header from "../components/Shared/Header";
import { Wrapper } from "../components/styles/Wrapper.styled";

const FormWrapper = styled.div`
  padding: ${(props) => props.theme.space[1]};

  @media ${({ theme }) => theme.device.mobileL} {
    padding: ${(props) => props.theme.space[2]};
  }

  @media ${({ theme }) => theme.device.laptop} {
    padding: ${(props) => props.theme.space[3]};
  }
`;

function CarSubscription(props) {
  const {
    //Authentication
    idVerification,
    idVerificationRequired,

    //Forms
    insuranceDataCaptured,
    insuredDataCaptured,
    addressDataCaptured,
  } = useContext(MyContext);

  return (
    <Wrapper>
      <Header
        name="Price Insure"
        description="Empowering Your Future, Protecting Your Finances"
        logoPath="../images/laminin-logo.png"
      />

      {!idVerification && idVerificationRequired && <Authentication />}

      {(idVerification || !idVerificationRequired) && (
        <FormWrapper>
          {!insuredDataCaptured && (
            <Insured
              name="Price Insure"
              formName="Car Subscription Insurance"
            />
          )}

          {!addressDataCaptured && insuredDataCaptured && (
            <Address
              name="Price Insure"
              formName="Car Subscription Insurance"
            />
          )}

          {!insuranceDataCaptured &&
            addressDataCaptured &&
            insuredDataCaptured && (
              <Insurance
                name="Price Insure"
                formName="Car Subscription Insurance"
              />
            )}

          {insuranceDataCaptured &&
            addressDataCaptured &&
            insuredDataCaptured && (
              <Vehicles
                name="Price Insure"
                formName="Car Subscription Insurance"
              />
            )}
        </FormWrapper>
      )}
    </Wrapper>
  );
}

export default CarSubscription;
