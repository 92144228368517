//NPM Packages
import React from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Theme
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/Theme";

//Context
import MyProvider from "../context/MyProvider";

//Components
import CarSubscription from "../routes/CarSubscription";
import Footer from "../components/Shared/Footer";
import CarSubscriptionQuote from "../routes/CarSubscriptionQuote";
import EducationPriceInsuranceQuote from "../routes/EducationPriceInsuranceQuote";
import FoodPriceInsuranceQuote from "../routes/FoodPriceInsuranceQuote";
import FuelPriceInsuranceQuote from "../routes/FuelPriceInsuranceQuote";
import FuneralSubscriptionQuote from "../routes/FuneralSubscriptionQuote";
import HealthPriceInsuranceQuote from "../routes/HealthPriceInsuranceQuote";
import HealthSubscriptionQuote from "../routes/HealthSubscriptionQuote";
import LegalSubscriptionQuote from "../routes/LegalSubscriptionQuote";
import PropertySubscriptionQuote from "../routes/PropertySubscriptionQuote";
import StockPriceInsuranceQuote from "../routes/StockPriceInsuranceQuote";
import NewQuote from "../routes/NewQuote";

const history = createBrowserHistory();

function App() {
  return (
    <div className="main-container">
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <MyProvider>
            <Routes>
              <Route path="/car-sub" element={<CarSubscription />} />
              <Route path="/car-quote" element={<CarSubscriptionQuote />} />
              <Route
                path="/education-pi-quote"
                element={<EducationPriceInsuranceQuote />}
              />
              <Route
                path="/food-pi-quote"
                element={<FoodPriceInsuranceQuote />}
              />
              <Route
                path="/fuel-pi-quote"
                element={<FuelPriceInsuranceQuote />}
              />
              <Route
                path="/funeral-quote"
                element={<FuneralSubscriptionQuote />}
              />
              <Route path="/legal-quote" element={<LegalSubscriptionQuote />} />
              <Route
                path="/health-pi-quote"
                element={<HealthPriceInsuranceQuote />}
              />
              <Route
                path="/health-quote"
                element={<HealthSubscriptionQuote />}
              />
              <Route path="/new-quote" element={<NewQuote />} />
              <Route
                path="/property-quote"
                element={<PropertySubscriptionQuote />}
              />
              <Route
                path="/stock-pi-quote"
                element={<StockPriceInsuranceQuote />}
              />
            </Routes>
            <Footer />
          </MyProvider>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
