export const InsurerData = [
  {
    name: "Auto & General",
  },
  {
    name: "Budget Insurance",
  },
  {
    name: "Bryte",
  },
  {
    name: "Dial Direct",
  },
  {
    name: "Discovery Insure",
  },
  {
    name: "First for Women",
  },
  {
    name: "Guardrisk",
  },
  {
    name: "Hollard",
  },
  {
    name: "Infiniti",
  },
  {
    name: "King Price",
  },
  {
    name: "MiWay",
  },
  {
    name: "Momentum",
  },
  {
    name: "MUA",
  },
  {
    name: "Mutual and Federal Risk Finance",
  },
  {
    name: "New National (Affinity)",
  },
  {
    name: "Old Mutual Insurance",
  },
  {
    name: "Outsurance",
  },
  {
    name: "Renasa",
  },
  {
    name: "Safire",
  },
  {
    name: "Santam",
  },
  {
    name: "Virseker",
  },
  {
    name: "Zenith",
  },
  {
    name: "Other",
  },
];
