import React from "react";
import styled from "styled-components";
import { MdRemoveCircle } from "react-icons/md";

//Components
import { Table, Thead, Tbody, Tr, Th, Td } from "../../styles/Table.styled";

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: red;
  margin: 2px;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
`;

export default function VehicleComponent(props) {
  function handleClick() {
    props.onDelete(props.id);
  }

  return (
    <Table id={props.id}>
      <Thead>
        <Tr>
          <Th style={{ width: "33%" }}>Vehicle</Th>
          <Th style={{ width: "33%" }}>Registration Number</Th>
          <Th style={{ width: "33%" }}>Remove</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>
            {props.make} {props.model}
          </Td>
          <Td>{props.regNo}</Td>
          <Td>
            <Button type="button" onClick={handleClick}>
              <MdRemoveCircle />
            </Button>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
}
