//NPM Packages
import { React, useContext, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

//Styled Components
import { Col } from "../styles/Col";
import { Dropdown } from "../styles/Dropdown.styled";
import { Form } from "../styles/Form.styled";
import { Input } from "../styles/Input.styled";
import RadioButton from "../styles/RadioButton";
import { Row } from "../styles/Row";
import { TextArea } from "../styles/TextArea.styled";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormDescription = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormLabel = styled.label`
  padding: ${(props) => props.theme.space[1]};

  font-size: medium;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.primary};
`;

const LabelBottom = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  font-size: small;
`;

const LabelBottomWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
  color: red;
`;

export default function Quote(props) {
  const {
    data,
    setData,
    quoteDataCaptured,
    setQuoteDataCaptured,

    //Laminin
    headers,
    lamininError,
    setLamininError,
    setLamininResponse,
    lamininSent,
    setLamininSent,
    lamininSubmitted,
    setLamininSubmitted,

    //Errors
    formError,
    setFormError,
    mobileError,
    setMobileError,
  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
        product: props.product,
      };
    });
  };

  const saveLeadId = (leadId) => {
    setData((prevValue) => {
      return {
        ...prevValue,
        leadId: leadId,
      };
    });
  };

  const submitToLaminin = () => {
    setLamininSubmitted(true);

    // Enable this for local testing environment
    axios
      //   .post("http://localhost:5000/api/new-quote/create", data, {
      // Enable this for hosted production environment
      .post("api/new-quote/create", data, {
        headers: headers,
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setLamininResponse(response.data);
            console.log("Laminin Response OK: " + response.status);
            setLamininSent(true);
            const { NewLeadId } = response.data;
            saveLeadId(NewLeadId);
          } else {
            console.log("Laminin Response Not OK: " + response.status);
            setLamininError(true);
          }
        },
        (error) => {
          console.log("Laminin Response Error: " + error);
          setLamininError(true);
        }
      );
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    // Mobile number validation
    if (data.mobile.length !== 10 || isNaN(data.mobile)) {
      setMobileError(
        "Please enter a valid 10-digit phone number without any spaces, example 0812345678"
      );
      isValid = false;
    } else {
      setMobileError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      submitToLaminin();
      setQuoteDataCaptured(true);
    }
  };

  return (
    <div>
      {!quoteDataCaptured && (
        <Form onSubmit={handleFormSubmit}>
          <FormHeading>
            {props.name} - {props.formName}
          </FormHeading>
          <FormDescription>
            Please complete your information below
          </FormDescription>
          {formError && (
            <ValidationSubHeading>{formError}</ValidationSubHeading>
          )}
          <FormBody>
            <ItemWrapper>
              <FormLabel>Personal Details</FormLabel>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Title</Label>
                  <Dropdown
                    name="title"
                    onChange={handleInfoChange}
                    required={true}
                    value={data.title}
                    width="100%"
                  >
                    <option value="" disabled hidden>
                      Please select
                    </option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Ms">Ms</option>
                    <option value="Dr">Dr</option>
                    <option value="Prof">Prof</option>
                  </Dropdown>
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>First Name (Required)</Label>
                  <Input
                    type="text"
                    name="firstName"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.firstName}
                    width="100%"
                  />
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Last Name (Required)</Label>
                  <Input
                    type="text"
                    name="lastName"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.lastName}
                    width="100%"
                  />
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Mobile Telephone Number (Required)</Label>
                  <Input
                    type="text"
                    name="mobile"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.mobile}
                    width="100%"
                    maxLength={10}
                  />
                  {mobileError && (
                    <ValidationMessage>{mobileError}</ValidationMessage>
                  )}
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Email (Required)</Label>
                  <Input
                    type="email"
                    name="email"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.email}
                    width="100%"
                  />
                </Col>
              </Row>
              <Row>
                <Col $md={12} style={{ padding: "0 1% 0 0" }}>
                  <ItemWrapper>
                    <Label>Are you a South African citizen?</Label>
                    <ItemInnerWrapper>
                      <RadioButton
                        name="saCitizen"
                        id="Yes"
                        onChange={handleInfoChange}
                        value="Yes"
                        checked={data.saCitizen === "Yes"}
                        label="Yes"
                        required={true}
                      />
                      <RadioButton
                        name="saCitizen"
                        id="No"
                        onChange={handleInfoChange}
                        value="No"
                        checked={data.saCitizen === "No"}
                        label="No"
                        required={true}
                      />
                    </ItemInnerWrapper>
                  </ItemWrapper>
                </Col>
              </Row>
              <Row>
                <Col $md={12} style={{ padding: "0 1% 0 0" }}>
                  <ItemWrapper>
                    <Label>
                      Are you looking for a Personal or Business quote?
                    </Label>
                    <ItemInnerWrapper>
                      <RadioButton
                        name="typeOfPolicy"
                        id="Personal"
                        onChange={handleInfoChange}
                        value="Personal"
                        checked={data.typeOfPolicy === "Personal"}
                        label="Personal"
                        required={true}
                      />
                      <RadioButton
                        name="typeOfPolicy"
                        id="Business"
                        onChange={handleInfoChange}
                        value="Business"
                        checked={data.typeOfPolicy === "Business"}
                        label="Business"
                        required={true}
                      />
                    </ItemInnerWrapper>
                  </ItemWrapper>
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Message</Label>
                  <TextArea
                    type="textarea"
                    name="message"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={false}
                    value={data.message}
                    width="100%"
                    maxLength={450}
                  />
                  <LabelBottomWrapper>
                    <LabelBottom>
                      Characters remaining: {450 - data.message.length}
                      /450
                    </LabelBottom>
                  </LabelBottomWrapper>
                </Col>
              </Row>
            </ItemWrapper>
          </FormBody>
          <ButtonWrapper>
            <button
              className="btn btn-dark btn-md"
              type="submit"
              style={{ margin: "0 2px" }}
            >
              Submit
            </button>
          </ButtonWrapper>
        </Form>
      )}

      {quoteDataCaptured &&
        lamininSubmitted &&
        !(lamininSent || lamininError) && (
          <Form>
            <FormHeading>
              {props.name} - {props.formName}
            </FormHeading>
            <FormSubHeading>Declaration</FormSubHeading>
            <FormSubHeading>Processing...</FormSubHeading>
            <FormSubHeading>
              Submitting your information, please stand by...
            </FormSubHeading>
          </Form>
        )}

      {quoteDataCaptured && lamininSent && (
        <Form>
          <FormHeading>
            {props.name} - {props.formName}
          </FormHeading>
          <FormSubHeading>Declaration</FormSubHeading>
          <FormSubHeading>
            Thank you for submitting your information, your Reference Number
            (Laminin Lead ID) is {data.leadId}. One of our representatives will
            be in contact with you shortly.
          </FormSubHeading>
        </Form>
      )}

      {quoteDataCaptured && lamininError && (
        <Form>
          <FormHeading>
            {props.name} - {props.formName}
          </FormHeading>
          <FormSubHeading>Declaration</FormSubHeading>
          <FormSubHeading>Something went wrong</FormSubHeading>
          <FormSubHeading>
            Your information was not received, we apologise for the
            inconvenience. Please contact us on {props.phone}.
          </FormSubHeading>
        </Form>
      )}
    </div>
  );
}
