//NPM Packages
import { React, useContext } from "react";
import styled from "styled-components";

//Context
import MyContext from "../context/MyContext";

//Components
import Authentication from "../components/Shared/Authentication";
import GeneralQuote from "../components/SubscriptionQuote/GeneralQuote";

import Header from "../components/Shared/Header";
import { Wrapper } from "../components/styles/Wrapper.styled";

const FormWrapper = styled.div`
  padding: ${(props) => props.theme.space[1]};

  @media ${({ theme }) => theme.device.mobileL} {
    padding: ${(props) => props.theme.space[2]};
  }

  @media ${({ theme }) => theme.device.laptop} {
    padding: ${(props) => props.theme.space[3]};
  }
`;

export default function NewQuote(props) {
  const {
    //Authentication
    idVerification,
    idVerificationRequired,
  } = useContext(MyContext);

  return (
    <Wrapper>
      <Header
        name="Price Insure"
        description="Empowering Your Future, Protecting Your Finances"
        logoPath=""
      />

      {!idVerification && idVerificationRequired && <Authentication />}

      {(idVerification || !idVerificationRequired) && (
        <FormWrapper>
          <GeneralQuote
            name="Price Insure"
            formName="New Quote Form"
            phone="065 227 4855"
          />
        </FormWrapper>
      )}
    </Wrapper>
  );
}
