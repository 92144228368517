import React from "react";

//Components
import { Table, Thead, Tbody, Tr, Th, Td } from "../../styles/Table.styled";

export default function VehicleSubComponent(props) {
  return (
    <Table id={props.id}>
      <Thead>
        <Tr>
          <Th style={{ width: "33%" }}>{props.valueHeading}</Th>
          <Th style={{ width: "33%" }}>Subscription</Th>
          <Th style={{ width: "33%" }}></Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>R{props.value}</Td>
          <Td>R{props.sub}</Td>
          <Td></Td>
        </Tr>
      </Tbody>
    </Table>
  );
}
