import React from "react";

//Components
import { Table, Thead, Tbody, Tr, Th, Td } from "../../styles/Table.styled";

export default function VehicleSubClaimComponent(props) {
  return (
    <Table id={props.id}>
      <Thead>
        <Tr>
          <Th style={{ width: "33%" }}>Claim Amount</Th>
          <Th style={{ width: "33%" }}>Claim % of Car Value</Th>
          <Th style={{ width: "33%" }}>Premium Estimate</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>R{props.value * 0.2}</Td>
          <Td>20%</Td>
          <Td>R{props.claimSub20}</Td>
        </Tr>
        <Tr>
          <Td>R{props.value * 0.4}</Td>
          <Td>40%</Td>
          <Td>R{props.claimSub40}</Td>
        </Tr>
        <Tr>
          <Td>R{props.value * 0.6}</Td>
          <Td>60%</Td>
          <Td>R{props.claimSub60}</Td>
        </Tr>
        <Tr>
          <Td>R{props.value * 0.8}</Td>
          <Td>80%</Td>
          <Td>R{props.claimSub80}</Td>
        </Tr>
        <Tr>
          <Td>R{props.value}</Td>
          <Td>100%</Td>
          <Td>R{props.claimSub100}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
}
