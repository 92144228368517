//NPM Packages
import { React, useEffect, useContext, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
// import styled from "styled-components";

//Context
import MyContext from "../../context/MyContext";

// const Heading = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: ${({ align }) => align || "center"};
// `;

// const Logo = styled.img`
//   width: ${({ width }) => width || "100%"};
//   border-radius: 10px;
//   padding: ${(props) => props.theme.space[1]};
//   @media ${({ theme }) => theme.device.mobileL} {
//     padding: ${(props) => props.theme.space[2]};
//   }

//   @media ${({ theme }) => theme.device.laptop} {
//     padding: ${(props) => props.theme.space[3]};
//   }
// `;

export default function Header(props) {
  const {
    data,
    setData,
    docsData,
    setDocsData,

    //Authentication
    headers,
    setHeaders,
    setIdVerificationRequired,
    requestAuthentication,
    setRequestAuthentication,
  } = useContext(MyContext);

  const [leadIdRequest, setLeadIdRequest] = useState(false);

  //Authenticate
  useEffect(() => {
    if (!headers && !requestAuthentication) {
      setRequestAuthentication(true);
      axios
        // Enable this for local testing environment
        // .get("http://localhost:5000/api/authenticate")
        // Enable this for hosted production environment
        .get("api/authenticate")
        .then(
          (response) => {
            if (response.status === 200) {
              setHeaders({
                Authorization: "Bearer " + response.data.token,
                "Content-Type": "application/json",
              });
              console.log("Laminin Response: " + response.status);
            } else {
              console.log("Laminin Response: " + response.status);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
          }
        );
    }
  }, [headers, setHeaders, requestAuthentication, setRequestAuthentication]);

  //Set Lead Id
  useEffect(() => {
    if (
      new URLSearchParams(new URL(window.location.href).search).get("l") !==
        null &&
      !data.leadId &&
      !leadIdRequest
    ) {
      setLeadIdRequest(true);
      setIdVerificationRequired(true);
      setData((prevValue) => {
        return {
          ...prevValue,
          leadId: new URLSearchParams(new URL(window.location.href).search).get(
            "l"
          ),
        };
      });

      setDocsData((prevValue) => {
        return {
          ...prevValue,
          leadId: new URLSearchParams(new URL(window.location.href).search).get(
            "l"
          ),
        };
      });
    }
  }, [
    data,
    setData,
    docsData,
    setDocsData,
    setIdVerificationRequired,
    leadIdRequest,
  ]);

  return (
    <div>
      <Helmet>
        <title>{props.name}</title>
        <meta name="description" description={props.description} />
      </Helmet>
      {/* <Heading align={props.align}>
        <Logo src={props.logoPath} width={props.width} />
      </Heading> */}
    </div>
  );
}
