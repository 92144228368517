//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../../context/MyContext";

//Styled Components
import { Col } from "../../styles/Col";
import { Dropdown } from "../../styles/Dropdown.styled";
import { Form } from "../../styles/Form.styled";
import { Input } from "../../styles/Input.styled";
import RadioButton from "../../styles/RadioButton";
import { Row } from "../../styles/Row";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormDescription = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormLabel = styled.label`
  padding: ${(props) => props.theme.space[1]};

  font-size: medium;
`;

const Label = styled.label`
  padding: ${(props) => props.theme.space[1]};
  font-size: medium;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ItemInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
  color: red;
`;

export default function Finance(props) {
  const {
    data,
    setData,
    setRegularDriverDataCaptured,
    setFinanceDataCaptured,

    //Errors
    formError,
    setFormError,
    selectError,
    setSelectError,
  } = useContext(MyContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => setRegularDriverDataCaptured(false);

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    if (name === "isVehicleFinanced" && value === "Yes") {
      setData((prevValue) => {
        return {
          ...prevValue,
          financeCompany: "",
          financeAgreement: "",
          financeAccountNo: "",
          financeBranch: "",
          financeOutstanding: "",
          creditShortfallExtension: "",
        };
      });
    }

    if (name === "isVehicleFinanced" && value === "No") {
      setData((prevValue) => {
        return {
          ...prevValue,
          creditShortfallExtension: "No",
        };
      });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    // Is Finance Financed Not Selected
    if (data.isVehicleFinanced === "") {
      setSelectError("Please select an option");
      isValid = false;
    } else {
      setSelectError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      setFinanceDataCaptured(true);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormSubHeading>Finance Details</FormSubHeading>
      <FormDescription>
        Please complete the vehicle finance information below
      </FormDescription>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <FormLabel>Finance Details</FormLabel>
        <ItemWrapper>
          <Label>Is the vehicle financed? (Required)</Label>
          <ItemInnerWrapper>
            <RadioButton
              name="isVehicleFinanced"
              id="Yes"
              onChange={handleInfoChange}
              value="Yes"
              checked={data.isVehicleFinanced === "Yes"}
              label="Yes"
              required={true}
            />
            <RadioButton
              onChange={handleInfoChange}
              name="isVehicleFinanced"
              id="No"
              value="No"
              checked={data.isVehicleFinanced === "No"}
              label="No"
            />
          </ItemInnerWrapper>
          {selectError && <ValidationMessage>{selectError}</ValidationMessage>}
        </ItemWrapper>
        {data.isVehicleFinanced === "Yes" && (
          <ItemWrapper>
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Finance Company</Label>
                <Input
                  type="text"
                  name="financeCompany"
                  onChange={handleInfoChange}
                  placeholder="Finance Company Name"
                  required={false}
                  value={data.financeCompany}
                  width="100%"
                />
              </Col>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Type of Agreement</Label>
                <Dropdown
                  name="financeAgreement"
                  onChange={handleInfoChange}
                  required={false}
                  value={data.financeAgreement}
                  width="100%"
                >
                  <option value="" disabled hidden>
                    Please select
                  </option>
                  <option value="Instalment finance">Instalment finance</option>
                  <option value="Instalment finance with a balloon payment">
                    Instalment finance with a balloon payment
                  </option>
                  <option value="Leasing">Leasing</option>
                  <option value="Guaranteed Future Value (GFV)">
                    Guaranteed Future Value (GFV)
                  </option>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Account Number</Label>
                <Input
                  type="text"
                  name="financeAccountNo"
                  onChange={handleInfoChange}
                  placeholder="Account"
                  required={false}
                  value={data.financeAccountNo}
                  width="100%"
                />
              </Col>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Branch</Label>
                <Input
                  type="text"
                  name="financeBranch"
                  onChange={handleInfoChange}
                  placeholder="Branch"
                  required={false}
                  value={data.financeBranch}
                  width="100%"
                />
              </Col>
            </Row>
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>Outstanding Amount</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  onWheel={(e) => e.target.blur()}
                  name="financeOutstanding"
                  onChange={handleInfoChange}
                  placeholder="Amount"
                  required={false}
                  value={data.financeOutstanding}
                  width="100%"
                />
              </Col>
            </Row>
            <Row>
              <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                <Label>
                  Do you require Credit Shortfall Extension (Cover on
                  outstanding loan amount similar to credit life)
                </Label>
                <Dropdown
                  name="creditShortfallExtension"
                  onChange={handleInfoChange}
                  required={false}
                  value={data.creditShortfallExtension}
                  width="100%"
                >
                  <option value="" disabled hidden>
                    Please select
                  </option>
                  <option value="Full amount">Full amount</option>
                  <option value="90%">90%</option>
                  <option value="80%">80%</option>
                  <option value="70%">70%</option>
                  <option value="60%">60%</option>
                  <option value="50%">50%</option>
                  <option value="40%">40%</option>
                  <option value="30%">30%</option>
                  <option value="20%">20%</option>
                  <option value="No">No</option>
                </Dropdown>
              </Col>
            </Row>
          </ItemWrapper>
        )}
      </FormBody>
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Next
        </button>
      </ButtonWrapper>
    </Form>
  );
}
