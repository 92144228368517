import { React, useContext, useEffect } from "react";
import axios from "axios";

//Context
import MyContext from "../../context/MyContext";

function Footer() {
  const { data, setData, headers } = useContext(MyContext);
  const year = new Date().getFullYear();

  //Get IP Address
  useEffect(() => {
    if (!data.ip && headers !== null) {
      axios
        // Enable this for local testing environment
        // .post("http://localhost:5000/api/get-ip", data, {
        // Enable this for hosted production environment
        .post("api/get-ip", data, {
          headers: headers,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              console.log("Laminin Response: " + response.status);
              setData((prevValue) => {
                return {
                  ...prevValue,
                  ip: response.data,
                };
              });
            } else {
              console.log("Laminin Response: " + response.status);
            }
          },
          (error) => {
            console.log("Laminin Response Error: " + error);
          }
        );
    }
  }, [data, headers, setData]);

  //Get Geo Location
  useEffect(() => {
    if (
      "geolocation" in navigator &&
      data.position.latitude === null &&
      data.position.longitude === null
    ) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setData((prevValue) => {
          return {
            ...prevValue,
            position: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          };
        });
      });
    }
  }, [data, setData]);

  return (
    <footer>
      {data.ip && <p>IP Address: {data.ip}</p>}
      {data.position.latitude !== null && data.position.longitude !== null ? (
        <p>
          Latitude: {data.position.latitude}, Longitude:{" "}
          {data.position.longitude}
        </p>
      ) : (
        <p>Geolocation is not available in your browser</p>
      )}
      <p>Laminin Copyright ⓒ {year}</p>
    </footer>
  );
}

export default Footer;
