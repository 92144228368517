//NPM Packages
import { React, useContext, useEffect } from "react";
import styled from "styled-components";

//Context
import MyContext from "../../../context/MyContext";

//Data
import { ProvinceData } from "../../../data/ProvinceData";

//Styled Components
import { Col } from "../../styles/Col";
import { Dropdown } from "../../styles/Dropdown.styled";
import { Form } from "../../styles/Form.styled";
import GoogleAddress from "../../Shared/GoogleAddress";
import { Input } from "../../styles/Input.styled";
import RadioButton from "../../styles/RadioButton";
import { Row } from "../../styles/Row";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  margin-top: 1rem;
`;

const FormHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: x-large;
`;

const FormSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormDescription = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
`;

const FormBody = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: large;
`;

const FormLabel = styled.label`
  padding: ${(props) => props.theme.space[1]};
  font-size: medium;
`;

const Label = styled.label`
  padding-left: ${(props) => props.theme.space[1]};
  padding-top: ${(props) => props.theme.space[1]};
  font-size: medium;
  color: ${({ theme }) => theme.colors.primary};
`;

const ItemInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: row;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
`;

const ValidationMessage = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: small;
  color: red;
`;

const ValidationSubHeading = styled.div`
  padding: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[0]};
  font-size: medium;
  color: red;
`;

export default function NightAddress(props) {
  const {
    data,
    setData,
    vehicles,
    addressSameError,
    setAddressSameError,
    addressSecureError,
    setAddressSecureError,
    formError,
    setFormError,
    postalCodeError,
    setPostalCodeError,
    setDayAddressDataCaptured,
    setVehicleDataCaptured,
    setRegisteredOwnerDataCaptured,
    setRegularDriverDataCaptured,
    setFinanceDataCaptured,
    setRiskAddressDataCaptured,
    setCaptureVehicle,
  } = useContext(MyContext);

  let riskScore = 0;
  let riskCounter = 0;
  let riskRatio = 0;
  let sub = 0;
  let claimSub20 = 0;
  let claimSub40 = 0;
  let claimSub60 = 0;
  let claimSub80 = 0;
  let claimSub100 = 0;
  let totalSub20 = 0;
  let totalSub40 = 0;
  let totalSub60 = 0;
  let totalSub80 = 0;
  let totalSub100 = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const back = () => setDayAddressDataCaptured(false);

  const calculateRisk = () => {
    const ageRiskScore = ageRisk(Number(data.age));
    const ageRegularDriverRiskScore = ageRisk(Number(data.regularDriverAge));
    const ageRegisteredOwnerRiskScore = ageRisk(
      Number(data.registeredOwnerAge)
    );
    const claimsInPast12MonthsScore = claimsInPast12MonthsRisk(
      data.insuranceClaimsLast12Months
    );
    const claimsInPast24MonthsScore = claimsInPast24MonthsRisk(
      data.insuranceClaimsLast24Months
    );
    const claimsInPast36MonthsScore = claimsInPast36MonthsRisk(
      data.insuranceClaimsLast36Months
    );
    const creditShortfallScore = creditShortfallRisk(
      data.creditShortfallExtension
    );
    const crimeAreaScore = crimeAreaRisk(data.suburb);
    const crimeRiskAreaScore = crimeAreaRisk(data.riskSuburb);
    const crimeDayAreaScore = crimeAreaRisk(data.daySuburb);
    const crimeNightAreaScore = crimeAreaRisk(data.nightSuburb);
    const currentlyInsuredScore = currentlyInsuredRisk(data.currentlyInsured);
    const engineCapacityScore = engineCapacityRisk(data.vehicleEngineCapacity);
    const genderRiskScore = genderRisk(data.gender);
    const genderRegularDriverRiskScore = genderRisk(data.regularDriverGender);
    const genderRegisteredOwnerRiskScore = genderRisk(
      data.registeredOwnerGender
    );
    const insurerDurationScore = insurerDurationRisk(data.insurerDuration);
    const licenseTypeScore = licenseTypeRisk(data.insuredDriversLicenceCode);
    const licenseTypeRegularDriverScore = licenseTypeRisk(
      data.regularDriverDriversLicenceCode
    );
    const licenseTypeRegisteredOwnerScore = licenseTypeRisk(
      data.registeredOwnerDriversLicenceCode
    );
    const maritalRiskScore = maritalRisk(data.maritalStatus);
    const maritalRegularDriverRiskScore = maritalRisk(
      data.regularDriverMaritalStatus
    );
    const maritalRegisteredOwnerRiskScore = maritalRisk(
      data.registeredOwnerMaritalStatus
    );
    const occupationRiskScore = occupationRisk(data.occupation);
    const occupationRegularDriverRiskScore = occupationRisk(
      data.regularDriverOccupation
    );
    const occupationRegisteredOwnerRiskScore = occupationRisk(
      data.registeredOwnerOccupation
    );
    const residenceAreaScore = residenceAreaRisk(data.addressType);
    const residenceRiskAreaScore = residenceAreaRisk(data.riskAddressType);
    const residenceDayAreaScore = residenceAreaRisk(data.dayAddressType);
    const residenceNightAreaScore = residenceAreaRisk(data.nightAddressType);
    const uninteruptedInsurancePast36MonthsScore =
      uninteruptedInsurancePast36MonthsRisk(
        data.insuranceUninterruptedPast36Months
      );
    const vehicleModelScore = vehicleModelRisk(
      data.vehicleMake,
      data.vehicleModel
    );
    const vehicleModificationScore = vehicleModificationRisk(
      data.vehicleModifications
    );
    const vehicleTrackerScore = vehicleTrackerRisk(
      data.vehicleAntiTheftInstalled
    );
    const vehicleTypeScore = vehicleTypeRisk(data.vehicleType);
    const vehicleUseScore = vehicleUseRisk(data.vehicleUse);
    const vehicleYearScore = vehicleYearRisk(Number(data.vehicleAge));
    const vehicleDriveToWorkPerWeekScore = vehicleDriveToWorkPerWeekRisk(
      data.vehicleDriveToWorkPerWeek
    );
    const vehicleRegisteredCode3Score = vehicleRegisteredCode3Risk(
      data.vehicleRegisteredCode3
    );
    const dayAddressSecureScore = addressSecureRisk(data.dayAddressSecure);
    const nightAddressSecureScore = addressSecureRisk(data.nightAddressSecure);

    //Gender
    riskScore += genderRiskScore;
    if (genderRiskScore > 0) riskCounter++;
    // console.log(`genderRiskScore: ${genderRiskScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    riskScore += genderRegularDriverRiskScore;
    if (genderRegularDriverRiskScore > 0) riskCounter++;
    // console.log(
    //   `genderRegularDriverRiskScore: ${genderRegularDriverRiskScore}`
    // );
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    riskScore += genderRegisteredOwnerRiskScore;
    if (genderRegisteredOwnerRiskScore > 0) riskCounter++;
    // console.log(
    //   `genderRegisteredOwnerRiskScore: ${genderRegisteredOwnerRiskScore}`
    // );
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Physical Address
    if (crimeAreaScore !== 0) riskScore += crimeAreaScore;
    if (crimeAreaScore === 0) riskScore += residenceAreaScore;
    if (crimeAreaScore > 0 || residenceAreaScore > 0) riskCounter++;
    // console.log(`crimeAreaScore: ${crimeAreaScore}`);
    // console.log(`residenceAreaScore: ${residenceAreaScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Risk Address
    if (crimeRiskAreaScore !== 0) riskScore += crimeRiskAreaScore;
    if (crimeRiskAreaScore === 0) riskScore += residenceRiskAreaScore;
    if (crimeRiskAreaScore > 0 || residenceRiskAreaScore > 0) riskCounter++;
    // console.log(`crimeRiskAreaScore: ${crimeRiskAreaScore}`);
    // console.log(`residenceRiskAreaScore: ${residenceRiskAreaScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Day Address
    if (crimeDayAreaScore !== 0) riskScore += crimeDayAreaScore;
    if (crimeDayAreaScore === 0) riskScore += residenceDayAreaScore;
    if (crimeDayAreaScore > 0 || residenceDayAreaScore > 0) riskCounter++;
    // console.log(`crimeDayAreaScore: ${crimeDayAreaScore}`);
    // console.log(`residenceDayAreaScore: ${residenceDayAreaScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Night Address
    if (crimeNightAreaScore !== 0) riskScore += crimeNightAreaScore;
    if (crimeNightAreaScore === 0) riskScore += residenceNightAreaScore;
    if (crimeNightAreaScore > 0 || residenceNightAreaScore > 0) riskCounter++;
    // console.log(`crimeNightAreaScore: ${crimeNightAreaScore}`);
    // console.log(`residenceNightAreaScore: ${residenceNightAreaScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Age
    riskScore += ageRiskScore;
    if (ageRiskScore > 0) riskCounter++;
    // console.log(`ageRiskScore: ${ageRiskScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    riskScore += ageRegularDriverRiskScore;
    if (ageRegularDriverRiskScore > 0) riskCounter++;
    // console.log(`ageRegularDriverRiskScore: ${ageRegularDriverRiskScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    riskScore += ageRegisteredOwnerRiskScore;
    if (ageRegisteredOwnerRiskScore > 0) riskCounter++;
    // console.log(`ageRegisteredOwnerRiskScore: ${ageRegisteredOwnerRiskScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Marital
    riskScore += maritalRiskScore;
    if (maritalRiskScore > 0) riskCounter++;
    // console.log(`maritalRiskScore: ${maritalRiskScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    riskScore += maritalRegularDriverRiskScore;
    if (maritalRegularDriverRiskScore > 0) riskCounter++;
    // console.log(
    //   `maritalRegularDriverRiskScore: ${maritalRegularDriverRiskScore}`
    // );
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    riskScore += maritalRegisteredOwnerRiskScore;
    if (maritalRegisteredOwnerRiskScore > 0) riskCounter++;
    // console.log(
    //   `maritalRegisteredOwnerRiskScore: ${maritalRegisteredOwnerRiskScore}`
    // );
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Occupation
    riskScore += occupationRiskScore;
    if (occupationRiskScore > 0) riskCounter++;
    // console.log(`occupationRiskScore: ${occupationRiskScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    riskScore += occupationRegularDriverRiskScore;
    if (occupationRegularDriverRiskScore > 0) riskCounter++;
    // console.log(
    //   `occupationRegularDriverRiskScore: ${occupationRegularDriverRiskScore}`
    // );
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    riskScore += occupationRegisteredOwnerRiskScore;
    if (occupationRegisteredOwnerRiskScore > 0) riskCounter++;
    // console.log(
    //   `occupationRegisteredOwnerRiskScore: ${occupationRegisteredOwnerRiskScore}`
    // );
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //License Type
    riskScore += licenseTypeScore;
    if (licenseTypeScore > 0) riskCounter++;
    // console.log(`licenseTypeScore: ${licenseTypeScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    riskScore += licenseTypeRegularDriverScore;
    if (licenseTypeRegularDriverScore > 0) riskCounter++;
    // console.log(
    //   `licenseTypeRegularDriverScore: ${licenseTypeRegularDriverScore}`
    // );
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    riskScore += licenseTypeRegisteredOwnerScore;
    if (licenseTypeRegisteredOwnerScore > 0) riskCounter++;
    // console.log(
    //   `licenseTypeRegisteredOwnerScore: ${licenseTypeRegisteredOwnerScore}`
    // );
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Credit shortfall
    riskScore += creditShortfallScore;
    if (creditShortfallScore > 0) riskCounter++;
    // console.log(`creditShortfallScore: ${creditShortfallScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Currently Insured
    riskScore += currentlyInsuredScore;
    if (currentlyInsuredScore > 0) riskCounter++;
    // console.log(`currentlyInsuredScore: ${currentlyInsuredScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Claims in past 12 months
    riskScore += claimsInPast12MonthsScore;
    if (claimsInPast12MonthsScore > 0) riskCounter++;
    // console.log(`claimsInPast12MonthsScore: ${claimsInPast12MonthsScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Claims in past 24 months
    riskScore += claimsInPast24MonthsScore;
    if (claimsInPast24MonthsScore > 0) riskCounter++;
    // console.log(`claimsInPast24MonthsScore: ${claimsInPast24MonthsScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Claims in past 36 months
    riskScore += claimsInPast36MonthsScore;
    if (claimsInPast36MonthsScore > 0) riskCounter++;
    // console.log(`claimsInPast36MonthsScore: ${claimsInPast36MonthsScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Uninterupted insurance past 36 months
    riskScore += uninteruptedInsurancePast36MonthsScore;
    if (uninteruptedInsurancePast36MonthsScore > 0) riskCounter++;
    // console.log(
    //   `uninteruptedInsurancePast36MonthsScore: ${uninteruptedInsurancePast36MonthsScore}`
    // );
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Duration of current insurance
    riskScore += insurerDurationScore;
    if (insurerDurationScore > 0) riskCounter++;
    // console.log(`insurerDurationScore: ${insurerDurationScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Model
    riskScore += vehicleModelScore;
    if (vehicleModelScore > 0) riskCounter++;
    // console.log(`vehicleModelScore: ${vehicleModelScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Vehicle been modified
    riskScore += vehicleModificationScore;
    if (vehicleModificationScore > 0) riskCounter++;
    // console.log(`vehicleModificationScore: ${vehicleModificationScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //What will vehicle be used for
    riskScore += vehicleUseScore;
    if (vehicleUseScore > 0) riskCounter++;
    // console.log(`vehicleUseScore: ${vehicleUseScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Year of manufacture
    riskScore += vehicleYearScore;
    if (vehicleYearScore > 0) riskCounter++;
    // console.log(`vehicleYearScore: ${vehicleYearScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Engine capacity
    riskScore += engineCapacityScore;
    if (engineCapacityScore > 0) riskCounter++;
    // console.log(`engineCapacityScore: ${engineCapacityScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Vehicle type
    riskScore += vehicleTypeScore;
    if (vehicleTypeScore > 0) riskCounter++;
    // console.log(`vehicleTypeScore: ${vehicleTypeScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //How many times do you drive to work per week?
    riskScore += vehicleDriveToWorkPerWeekScore;
    if (vehicleDriveToWorkPerWeekScore > 0) riskCounter++;
    // console.log(
    //   `vehicleDriveToWorkPerWeekScore: ${vehicleDriveToWorkPerWeekScore}`
    // );
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Was vehicle registered as Code 3
    riskScore += vehicleRegisteredCode3Score;
    if (vehicleRegisteredCode3Score > 0) riskCounter++;
    // console.log(`vehicleRegisteredCode3Score: ${vehicleRegisteredCode3Score}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Vehicle day parking covered
    riskScore += dayAddressSecureScore;
    if (dayAddressSecureScore > 0) riskCounter++;
    // console.log(`dayAddressSecureScore: ${dayAddressSecureScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Vehicle night parking covered
    riskScore += nightAddressSecureScore;
    if (nightAddressSecureScore > 0) riskCounter++;
    // console.log(`nightAddressSecureScore: ${nightAddressSecureScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Vehicle fitted with tracker?
    riskScore += vehicleTrackerScore;
    if (vehicleTrackerScore > 0) riskCounter++;
    // console.log(`vehicleTrackerScore: ${vehicleTrackerScore}`);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log("*************************");

    //Calculations
    riskRatio = riskScore / (riskCounter * 10);
    // console.log(`riskScore: ${riskScore}`);
    // console.log(`riskCounter: ${riskCounter}`);
    // console.log(`riskRatio: ${riskRatio}`);
    // console.log("*************************");
  };

  const calculateSub = () => {
    let value = Number(data.vehicleValue);
    if (value === 0) {
      value = Number(data.vehicleEstimatedValue);
    }

    if (value <= 499999) {
      sub = 349;
    } else if (value >= 500000 && value <= 999999) {
      sub = 699;
    } else if (value >= 1000000 && value <= 1499999) {
      sub = 1049;
    } else if (value >= 1500000 && value <= 1999999) {
      sub = 1399;
    } else if (value >= 2000000 && value <= 2999999) {
      sub = 1999;
    } else {
      sub = 0;
    }

    setData((prevValue) => {
      return {
        ...prevValue,
        insuranceSub: sub,
      };
    });

    return sub;
  };

  const calculateClaimSub = () => {
    const generalFactor = 90;
    let value = Number(data.vehicleValue);
    if (value === 0) {
      value = Number(data.vehicleEstimatedValue);
    }

    claimSub20 =
      calculateSub() * 1.2 + (value * 0.25 * riskRatio) / (generalFactor - 25);
    totalSub20 = claimSub20 * 48;

    claimSub40 =
      calculateSub() * 1.4 + (value * 0.5 * riskRatio) / (generalFactor - 25);
    totalSub40 = claimSub40 * 48;

    claimSub60 =
      calculateSub() * 1.6 + (value * 0.75 * riskRatio) / (generalFactor - 25);
    totalSub60 = claimSub60 * 48;

    claimSub80 =
      calculateSub() * 1.15 + (value * 0.9 * riskRatio) / (generalFactor - 25);
    totalSub80 = claimSub80 * 48;

    claimSub100 =
      calculateSub() * 1.1 + (value * 1.05 * riskRatio) / (generalFactor - 20);
    totalSub100 = claimSub100 * 48;

    claimSub20 = Math.round(claimSub20 * 100) / 100;
    claimSub40 = Math.round(claimSub40 * 100) / 100;
    claimSub60 = Math.round(claimSub60 * 100) / 100;
    claimSub80 = Math.round(claimSub80 * 100) / 100;
    claimSub100 = Math.round(claimSub100 * 100) / 100;
    totalSub20 = Math.round(totalSub20 * 100) / 100;
    totalSub40 = Math.round(totalSub40 * 100) / 100;
    totalSub60 = Math.round(totalSub60 * 100) / 100;
    totalSub80 = Math.round(totalSub80 * 100) / 100;
    totalSub100 = Math.round(totalSub100 * 100) / 100;

    setData((prevValue) => {
      return {
        ...prevValue,
        insuranceClaimSub20: claimSub20,
        insuranceClaimSub40: claimSub40,
        insuranceClaimSub60: claimSub60,
        insuranceClaimSub80: claimSub80,
        insuranceClaimSub100: claimSub100,
        insuranceClaimTotal20: totalSub20,
        insuranceClaimTotal40: totalSub40,
        insuranceClaimTotal60: totalSub60,
        insuranceClaimTotal80: totalSub80,
        insuranceClaimTotal100: totalSub100,
      };
    });
  };

  const addressSecureRisk = (secure) => {
    let risk = 0;

    switch (secure) {
      case "No":
        risk = 8;
        break;

      case "Varies":
        risk = 6;
        break;

      case "Yes":
        risk = 4;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const ageRisk = (age) => {
    let risk = 0;

    if (age <= 16 && age <= 20) {
      risk = 10;
    } else if (age <= 21 && age <= 25) {
      risk = 9;
    } else if (age <= 26 && age <= 30) {
      risk = 8;
    } else if (age <= 31 && age <= 35) {
      risk = 7;
    } else if (age <= 36 && age <= 40) {
      risk = 6;
    } else if (age <= 41 && age <= 45) {
      risk = 5;
    } else if (age <= 46 && age <= 50) {
      risk = 4;
    } else if (age <= 51 && age <= 55) {
      risk = 3;
    } else if (age <= 56 && age <= 60) {
      risk = 2;
    } else if (age >= 61) {
      risk = 1;
    } else {
      risk = 0;
    }
    return risk;
  };

  const claimsInPast12MonthsRisk = (claims) => {
    let risk = 0;

    switch (claims) {
      case "2+":
        risk = 10;
        break;

      case "1":
        risk = 9;
        break;

      case "Lights":
        risk = 5;
        break;

      case "Windscreen":
        risk = 4;
        break;

      case "0":
        risk = 1;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const claimsInPast24MonthsRisk = (claims) => {
    let risk = 0;

    switch (claims) {
      case "3+":
        risk = 10;
        break;

      case "2":
        risk = 9;
        break;

      case "1":
        risk = 7;
        break;

      case "Lights":
        risk = 5;
        break;

      case "Windscreen":
        risk = 4;
        break;

      case "0":
        risk = 1;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const claimsInPast36MonthsRisk = (claims) => {
    let risk = 0;

    switch (claims) {
      case "5+":
        risk = 10;
        break;

      case "4":
        risk = 9;
        break;

      case "3":
        risk = 8;
        break;

      case "2":
        risk = 7;
        break;

      case "1":
        risk = 6;
        break;

      case "Lights":
        risk = 5;
        break;

      case "Windscreen":
        risk = 4;
        break;

      case "0":
        risk = 1;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const creditShortfallRisk = (shortfall) => {
    let risk = 0;

    switch (shortfall) {
      case "Full Amount":
        risk = 10;
        break;

      case "90%":
        risk = 9;
        break;

      case "80%":
        risk = 8;
        break;

      case "70%":
        risk = 7;
        break;

      case "60%":
        risk = 6;
        break;

      case "50%":
        risk = 5;
        break;

      case "40%":
        risk = 4;
        break;

      case "30%":
        risk = 3;
        break;

      case "20%":
        risk = 2;
        break;

      case "No":
        risk = 1;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const crimeAreaRisk = (suburb, province) => {
    let risk = 0;

    switch (suburb) {
      //Top 5 Crime
      case "Ivory Park":
        if (province === "Gauteng") risk = 10;
        break;

      case "Philippi East":
        if (province === "Western Cape") risk = 10;
        break;

      case "Harare":
        if (province === "Western Cape") risk = 10;
        break;

      case "Nyanga":
        if (province === "Western Cape") risk = 10;
        break;

      case "Moroka":
        if (province === "Gauteng") risk = 10;
        break;

      //6-10 Crime
      case "Loate":
        if (province === "Gauteng") risk = 9;
        break;

      case "Orange Farms":
        if (province === "Gauteng") risk = 9;
        break;

      case "Eldorado Park":
        if (province === "Gauteng") risk = 9;
        break;

      case "Tembisa":
        if (province === "Gauteng") risk = 9;
        break;

      case "Jabulani":
        if (province === "Gauteng") risk = 9;
        break;

      //11-15 Crime
      case "Mamelodi East":
        if (province === "Gauteng") risk = 8;
        break;

      case "Midrand":
        if (province === "Gauteng") risk = 8;
        break;

      case "Mfuleni":
        if (province === "Western Cape") risk = 8;
        break;

      case "Umlazi":
        if (province === "KwaZulu-Natal") risk = 8;
        break;

      case "Lenasia":
        if (province === "Gauteng") risk = 8;
        break;

      //16-20 Crime
      case "Delft":
        if (province === "Western Cape") risk = 7;
        break;

      case "Lingelethu-West":
        if (province === "Western Cape") risk = 7;
        break;

      case "Olievanhoutbosch":
        if (province === "Gauteng") risk = 7;
        break;

      case "New Brighton":
        if (province === "Eastern Cape") risk = 7;
        break;

      case "Kwazakele":
        if (province === "Eastern Cape") risk = 7;
        break;

      //21-25 Crime
      case "Jeppe":
        if (province === "Gauteng") risk = 6;
        break;

      case "Pretoria West":
        if (province === "Gauteng") risk = 6;
        break;

      case "Marianhill":
        if (province === "KwaZulu-Natal") risk = 6;
        break;

      case "Khayelitsha":
        if (province === "Western Cape") risk = 6;
        break;

      case "Kempton Park":
        if (province === "Gauteng") risk = 6;
        break;

      //26-30
      case "Motherwell":
        if (province === "Eastern Cape") risk = 5;
        break;

      case "Rabie Ridge":
        if (province === "Gauteng") risk = 5;
        break;

      case "Germiston":
        if (province === "Gauteng") risk = 5;
        break;

      case "Bramley":
        if (province === "Gauteng") risk = 5;
        break;

      case "Vosman":
        if (province === "Mpumulanga") risk = 5;
        break;

      default:
        risk = 0;
        break;
    }
    return risk;
  };

  const currentlyInsuredRisk = (currentlyInsured) => {
    let risk = 0;

    switch (currentlyInsured) {
      case "No":
        risk = 7;
        break;

      case "Yes":
        risk = 2;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const engineCapacityRisk = (size) => {
    let risk = 0;

    switch (size) {
      case "3 liter +":
        risk = 10;
        break;

      case "2 liter":
        risk = 8;
        break;

      case "1.6 liter":
        risk = 6;
        break;

      case "1 liter":
        risk = 4;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const insurerDurationRisk = (months) => {
    let risk = 0;

    switch (months) {
      case "Less than 3 months":
        risk = 10;
        break;

      case "Less than 6 months":
        risk = 9;
        break;

      case "Less than 9 months":
        risk = 8;
        break;

      case "Less than 12 months":
        risk = 7;
        break;

      case "Less than 15 months":
        risk = 6;
        break;

      case "Less than 18 months":
        risk = 5;
        break;

      case "Less than 24 months":
        risk = 4;
        break;

      case "Less than 36 months":
        risk = 3;
        break;

      case "Over 36 months":
        risk = 2;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const genderRisk = (gender) => {
    let risk = 0;

    switch (gender) {
      case "Male":
        risk = 7;
        break;

      case "Female":
        risk = 4;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const licenseTypeRisk = (licenseType) => {
    let risk = 0;

    switch (licenseType) {
      case "Code 8":
        risk = 7;
        break;

      case "Code 10":
        risk = 5;
        break;

      case "Code 14":
        risk = 4;
        break;

      case "Advanced Driving":
        risk = 3;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const maritalRisk = (maritalStatus) => {
    let risk = 0;

    switch (maritalStatus) {
      case "Single":
        risk = 7;
        break;

      case "In a relationship":
        risk = 5;
        break;

      case "Married":
        risk = 3;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const occupationRisk = (occupation) => {
    let risk = 0;

    switch (occupation) {
      case "Unskilled":
        risk = 7;
        break;

      case "Partially Skilled":
        risk = 6;
        break;

      case "Skilled":
        risk = 5;
        break;

      case "Business Owner":
        risk = 4;
        break;

      case "Professional":
        risk = 3;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const residenceAreaRisk = (residenceType) => {
    let risk = 0;
    switch (residenceType) {
      //Township Area
      case "Township Area":
        risk = 5;
        break;

      //Urban
      case "Urban":
        risk = 4;
        break;

      //City
      case "City":
        risk = 3;
        break;

      //Estate
      case "Estate":
        risk = 2;
        break;

      //Country Estate
      case "Country Estate":
        risk = 1;
        break;

      default:
        break;
    }
    return risk;
  };

  const uninteruptedInsurancePast36MonthsRisk = (uninteruptedInsurance) => {
    let risk = 0;

    switch (uninteruptedInsurance) {
      case "No":
        risk = 8;
        break;

      case "Not Sure":
        risk = 6;
        break;

      case "Yes":
        risk = 1;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const vehicleDriveToWorkPerWeekRisk = (amount) => {
    let risk = 0;

    switch (amount) {
      case "5":
        risk = 8;
        break;

      case "4":
        risk = 7;
        break;

      case "3":
        risk = 6;
        break;

      case "2":
        risk = 5;
        break;

      case "1":
        risk = 4;
        break;

      case "Varies":
        risk = 3;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const vehicleModelRisk = (make, model) => {
    let risk = 0;
    if (make === "Toyota" && model.match(/fortuner/gi)) {
      risk = 10;
    } else if (make === "Toyota" && model.match(/hilux/gi)) {
      risk = 10;
    } else if (make === "Toyota" && model.match(/corolla cross/gi)) {
      risk = 10;
    } else if (make === "Toyota" && model.match(/rav4/gi)) {
      risk = 9;
    } else if (make === "Volkswagen" && model.match(/polo/gi)) {
      risk = 9;
    } else if (make === "Nissan" && model.match(/np2000/gi)) {
      risk = 8;
    } else if (make === "Ford" && model.match(/ranger/gi)) {
      risk = 8;
    } else {
      risk = 0;
    }

    return risk;
  };

  const vehicleModificationRisk = (isModified) => {
    let risk = 0;

    switch (isModified) {
      case "Yes":
        risk = 9;
        break;

      case "No":
        risk = 2;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const vehicleRegisteredCode3Risk = (code3) => {
    let risk = 0;

    switch (code3) {
      case "Yes":
        risk = 8;
        break;

      case "No":
        risk = 2;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const vehicleTrackerRisk = (trackerInstalled) => {
    let risk = 0;

    switch (trackerInstalled) {
      case "No":
        risk = 10;
        break;

      case "Yes":
        risk = 4;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const vehicleTypeRisk = (type) => {
    let risk = 0;

    switch (type) {
      case "Sedan":
        risk = 10;
        break;

      case "Hatchback":
        risk = 10;
        break;

      case "Coupe":
        risk = 10;
        break;

      case "Bakkie":
        risk = 9;
        break;

      case "Panel Van":
        risk = 9;
        break;

      case "Motorcycle":
        risk = 8;
        break;

      case "SUV":
        risk = 7;
        break;

      case "Station Wagon":
        risk = 7;
        break;

      case "Microbus":
        risk = 6;
        break;

      case "Mini Bus":
        risk = 6;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const vehicleUseRisk = (use) => {
    let risk = 0;

    switch (use) {
      case "General":
        risk = 7;
        break;

      case "Events":
        risk = 6;
        break;

      case "School":
        risk = 5;
        break;

      case "Travelling":
        risk = 5;
        break;

      case "Work":
        risk = 5;
        break;

      default:
        risk = 0;
    }
    return risk;
  };

  const vehicleYearRisk = (vehicleAge) => {
    let risk = 0;

    if (vehicleAge >= 5) {
      risk = 10;
    } else if (vehicleAge === 4) {
      risk = 8;
    } else if (vehicleAge === 3) {
      risk = 7;
    } else if (vehicleAge === 2) {
      risk = 6;
    } else if (vehicleAge === 1) {
      risk = 5;
    } else if (vehicleAge === 0) {
      risk = 4;
    } else {
      risk = 0;
    }
    return risk;
  };

  const handleInfoChange = (event) => {
    const { name, value } = event.target;
    setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });

    if (name === "nightRiskAddressSame" && value === "Yes") {
      setData((prevValue) => {
        return {
          ...prevValue,
          nightAddressType: data.addressType,
          nightComplex: data.complex,
          nightStreetAddress: data.streetAddress,
          nightSuburb: data.suburb,
          nightCity: data.city,
          nightProvince: data.province,
          nightPostalCode: data.postalCode,
        };
      });
    }

    if (name === "nightRiskAddressSame" && value === "No") {
      setData((prevValue) => {
        return {
          ...prevValue,
          nightAddressType: "",
          nightComplex: "",
          nightStreetAddress: "",
          nightSuburb: "",
          nightCity: "",
          nightProvince: "",
          nightPostalCode: "",
        };
      });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let isValid = true;

    // Completion checks
    if (data.nightAddressSecure === "") {
      setAddressSecureError("Please select an option");
      isValid = false;
    } else {
      setAddressSecureError("");
      setFormError("");
    }

    if (data.nightRiskAddressSame === "") {
      setAddressSameError("Please select an option");
      isValid = false;
    } else {
      setAddressSameError("");
      setFormError("");
    }

    // Postal code validation
    if (data.nightPostalCode.length !== 4 || isNaN(data.nightPostalCode)) {
      setPostalCodeError(
        "Please enter a valid 4-digit Postal Code without any spaces"
      );
      isValid = false;
    } else {
      setPostalCodeError("");
      setFormError("");
    }

    if (!isValid) {
      setFormError(
        "Note: There are errors on this form. Please correct and click on Next to continue."
      );
      window.scrollTo(0, 0);
    }

    if (isValid) {
      // Process your form submission
      calculateRisk();
      calculateSub();
      calculateClaimSub();

      //Add data to vehicle array
      vehicles.push({
        //Insured Vehicle Data
        vehicleYear: data.vehicleYear,
        vehicleMake: data.vehicleMake,
        vehicleModel: data.vehicleModel,
        vehicleVinNo: data.vehicleVinNo,
        vehicleEngineNo: data.vehicleEngineNo,
        vehicleRegNo: data.vehicleRegNo,
        vehicleValue: data.vehicleValue,
        vehicleEstimatedValue: data.vehicleEstimatedValue,
        vehicleUse: data.vehicleUse,
        vehicleType: data.vehicleType,
        vehicleEngineCapacity: data.vehicleEngineCapacity,
        vehicleAntiTheftInstalled: data.vehicleAntiTheftInstalled,
        vehicleAntiTheftDetails: data.vehicleAntiTheftDetails,
        vehicleModifications: data.vehicleModifications,
        vehicleModificationsDetails: data.vehicleModificationsDetails,
        vehicleImmobiliser: data.vehicleImmobiliser,
        vehicleRegisteredCode3: data.vehicleRegisteredCode3,
        insuranceSub: sub,
        insuranceClaimSub20: claimSub20,
        insuranceClaimSub40: claimSub40,
        insuranceClaimSub60: claimSub60,
        insuranceClaimSub80: claimSub80,
        insuranceClaimSub100: claimSub100,
        insuranceClaimTotal20: totalSub20,
        insuranceClaimTotal40: totalSub40,
        insuranceClaimTotal60: totalSub60,
        insuranceClaimTotal80: totalSub80,
        insuranceClaimTotal100: totalSub100,

        //Insured Vehicle Risk Address
        riskAddressSame: data.riskAddressSame,
        riskAddressType: data.riskAddressType,
        riskComplex: data.riskComplex,
        riskStreetAddress: data.riskStreetAddress,
        riskSuburb: data.riskSuburb,
        riskCity: data.riskCity,
        riskProvince: data.riskProvince,
        riskPostalCode: data.riskPostalCode,

        //Insured Vehicle Day Address
        dayRiskAddressSame: data.dayRiskAddressSame,
        dayAddressType: data.dayAddressType,
        dayAddressSecure: data.dayAddressSecure,
        dayComplex: data.dayComplex,
        dayStreetAddress: data.dayStreetAddress,
        daySuburb: data.daySuburb,
        dayCity: data.dayCity,
        dayProvince: data.dayProvince,
        dayPostalCode: data.dayPostalCode,

        //Insured Vehicle Night Address
        nightRiskAddressSame: data.nightRiskAddressSame,
        nightAddressType: data.nightAddressType,
        nightAddressSecure: data.nightAddressSecure,
        nightComplex: data.nightComplex,
        nightStreetAddress: data.nightStreetAddress,
        nightSuburb: data.nightSuburb,
        nightCity: data.nightCity,
        nightProvince: data.nightProvince,
        nightPostalCode: data.nightPostalCode,

        //Insured Vehicle Finance Data
        isVehicleFinanced: data.isVehicleFinanced,
        financeCompany: data.financeCompany,
        financeAgreement: data.financeAgreement,
        financeAccountNo: data.financeAccountNo,
        financeBranch: data.financeBranch,
        financeOutstanding: data.financeOutstanding,
        creditShortfallExtension: data.creditShortfallExtension,

        //Insured Vehicle Regular Driver
        regularDriverSame: data.regularDriverSame,
        regularDriverTitle: data.regularDriverTitle,
        regularDriverFirstName: data.regularDriverFirstName,
        regularDriverLastName: data.regularDriverLastName,
        regularDriverIdType: data.regularDriverIdType,
        regularDriverId: data.regularDriverId,
        regularDriverOccupation: data.regularDriverOccupation,
        regularDriverGender: data.regularDriverGender,
        regularDriverDob: data.regularDriverDob,
        regularDriverAge: data.regularDriverAge,
        regularDriverMaritalStatus: data.regularDriverMaritalStatus,
        regularDriverDriversLicenceCode: data.regularDriverDriversLicenceCode,
        regularDriverMobile: data.regularDriverMobile,
        regularDriverWorkTelNo: data.regularDriverWorkTelNo,
        regularDriverAltTelNo: data.regularDriverAltTelNo,
        regularDriverEmail: data.regularDriverEmail,

        //Registered Owner
        registeredOwnerSame: data.registeredOwnerSame,
        registeredOwnerTitle: data.registeredOwnerTitle,
        registeredOwnerFirstName: data.registeredOwnerFirstName,
        registeredOwnerLastName: data.registeredOwnerLastName,
        registeredOwnerIdType: data.registeredOwnerIdType,
        registeredOwnerId: data.registeredOwnerId,
        registeredOwnerOccupation: data.registeredOwnerOccupation,
        registeredOwnerGender: data.registeredOwnerGender,
        registeredOwnerDob: data.registeredOwnerDob,
        registeredOwnerAge: data.registeredOwnerAge,
        registeredOwnerMaritalStatus: data.registeredOwnerMaritalStatus,
        registeredOwnerDriversLicenceCode:
          data.registeredOwnerDriversLicenceCode,
        registeredOwnerMobile: data.registeredOwnerMobile,
        registeredOwnerWorkTelNo: data.registeredOwnerWorkTelNo,
        registeredOwnerAltTelNo: data.registeredOwnerAltTelNo,
        registeredOwnerEmail: data.registeredOwnerEmail,
      });

      //Clear forms
      // setData((prevValue) => {
      //   return {
      //     ...prevValue,
      //     //Insured Vehicle Data
      //     vehicleYear: "",
      //     vehicleAge: "",
      //     vehicleMake: "",
      //     vehicleModel: "",
      //     vehicleVinNo: "",
      //     vehicleEngineNo: "",
      //     vehicleRegNo: "",
      //     vehicleValue: "",
      //     vehicleEstimatedValue: "",
      //     vehicleUse: "",
      //     vehicleDriveToWorkPerWeek: "",
      //     vehicleType: "",
      //     vehicleEngineCapacity: "",
      //     vehicleAntiTheftInstalled: "",
      //     vehicleAntiTheftDetails: "",
      //     vehicleModifications: "",
      //     vehicleModificationsDetails: "",
      //     vehicleImmobiliser: "",
      //     vehicleRegisteredCode3: "",
      //     insuranceSub: "",
      //     insuranceClaimSub20: "",
      //     insuranceClaimSub40: "",
      //     insuranceClaimSub60: "",
      //     insuranceClaimSub80: "",
      //     insuranceClaimSub100: "",
      //     insuranceClaimTotal20: "",
      //     insuranceClaimTotal40: "",
      //     insuranceClaimTotal60: "",
      //     insuranceClaimTotal80: "",
      //     insuranceClaimTotal100: "",

      //     //Insured Vehicle Risk Address
      //     riskAddressSame: "",
      //     riskAddressType: "",
      //     riskComplex: "",
      //     riskStreetAddress: "",
      //     riskSuburb: "",
      //     riskCity: "",
      //     riskProvince: "",
      //     riskPostalCode: "",

      //     //Insured Vehicle Day Address
      //     dayRiskAddressSame: "",
      //     dayAddressType: "",
      //     dayAddressSecure: "",
      //     dayComplex: "",
      //     dayStreetAddress: "",
      //     daySuburb: "",
      //     dayCity: "",
      //     dayProvince: "",
      //     dayPostalCode: "",

      //     //Insured Vehicle Night Address
      //     nightRiskAddressSame: "",
      //     nightAddressType: "",
      //     nightAddressSecure: "",
      //     nightComplex: "",
      //     nightStreetAddress: "",
      //     nightSuburb: "",
      //     nightCity: "",
      //     nightProvince: "",
      //     nightPostalCode: "",

      //     //Insured Vehicle Finance Data
      //     isVehicleFinanced: "",
      //     financeCompany: "",
      //     financeAgreement: "",
      //     financeAccountNo: "",
      //     financeBranch: "",
      //     financeOutstanding: "",
      //     creditShortfallExtension: "",

      //     //Insured Vehicle Regular Driver
      //     regularDriverSame: "",
      //     regularDriverTitle: "",
      //     regularDriverFirstName: "",
      //     regularDriverLastName: "",
      //     regularDriverIdType: "",
      //     regularDriverId: "",
      //     regularDriverOccupation: "",
      //     regularDriverGender: "",
      //     regularDriverDob: "",
      //     regularDriverAge: "",
      //     regularDriverMaritalStatus: "",
      //     regularDriverDriversLicenceCode: "",
      //     regularDriverMobile: "",
      //     regularDriverWorkTelNo: "",
      //     regularDriverAltTelNo: "",
      //     regularDriverEmail: "",

      //     //Registered Owner
      //     registeredOwnerSame: "",
      //     registeredOwnerTitle: "",
      //     registeredOwnerFirstName: "",
      //     registeredOwnerLastName: "",
      //     registeredOwnerIdType: "",
      //     registeredOwnerId: "",
      //     registeredOwnerOccupation: "",
      //     registeredOwnerGender: "",
      //     registeredOwnerDob: "",
      //     registeredOwnerAge: "",
      //     registeredOwnerMaritalStatus: "",
      //     registeredOwnerDriversLicenceCode: "",
      //     registeredOwnerMobile: "",
      //     registeredOwnerWorkTelNo: "",
      //     registeredOwnerAltTelNo: "",
      //     registeredOwnerEmail: "",
      //   };
      // });

      // console.log(JSON.stringify(vehicles, null, 2));

      setCaptureVehicle(false);
      setVehicleDataCaptured(false);
      setRegisteredOwnerDataCaptured(false);
      setRegularDriverDataCaptured(false);
      setFinanceDataCaptured(false);
      setRiskAddressDataCaptured(false);
      setDayAddressDataCaptured(false);
    }
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormHeading>
        {props.name} - {props.formName}
      </FormHeading>
      <FormSubHeading>Vehicle Night Address</FormSubHeading>
      <FormDescription>
        Please complete the vehicle night address information below
      </FormDescription>
      {formError && <ValidationSubHeading>{formError}</ValidationSubHeading>}
      <FormBody>
        <ItemWrapper>
          <Label>
            Is the vehicle parked in a secure location at night? (Required)
          </Label>
          <ItemInnerWrapper>
            <Dropdown
              name="nightAddressSecure"
              onChange={handleInfoChange}
              required={true}
              value={data.nightAddressSecure}
              width="100%"
            >
              <option value="" disabled hidden>
                Please select
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Varies">Varies</option>
            </Dropdown>
          </ItemInnerWrapper>
          {addressSecureError && (
            <ValidationSubHeading>{addressSecureError}</ValidationSubHeading>
          )}
        </ItemWrapper>
        <ItemWrapper>
          <Label>
            Is the Night Address the same as the Risk Address? (Required)
          </Label>
          <ItemInnerWrapper>
            <RadioButton
              name="nightRiskAddressSame"
              id="Yes"
              onChange={handleInfoChange}
              value="Yes"
              checked={data.nightRiskAddressSame === "Yes"}
              label="Yes"
              required={true}
            />
            <RadioButton
              name="nightRiskAddressSame"
              id="No"
              onChange={handleInfoChange}
              value="No"
              checked={data.nightRiskAddressSame === "No"}
              label="No"
              required={true}
            />
          </ItemInnerWrapper>
          {addressSameError && (
            <ValidationSubHeading>{addressSameError}</ValidationSubHeading>
          )}
        </ItemWrapper>

        {data.nightRiskAddressSame === "No" && (
          <div>
            <ItemWrapper>
              <FormLabel>Google Address Search</FormLabel>
              <GoogleAddress location="night" />
            </ItemWrapper>
            <ItemWrapper>
              <FormLabel>Physical Address Information</FormLabel>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Unit / Complex</Label>
                  <Input
                    type="text"
                    name="nightComplex"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={false}
                    value={data.nightComplex}
                    width="100%"
                  />
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Street Address (Required)</Label>
                  <Input
                    type="text"
                    name="nightStreetAddress"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.nightStreetAddress}
                    width="100%"
                  />
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Suburb (Required)</Label>
                  <Input
                    type="text"
                    name="nightSuburb"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.nightSuburb}
                    width="100%"
                  />
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>City (Required)</Label>
                  <Input
                    type="text"
                    name="nightCity"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.nightCity}
                    width="100%"
                  />
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Province (Required)</Label>
                  <Dropdown
                    name="nightProvince"
                    onChange={handleInfoChange}
                    required={true}
                    value={data.nightProvince}
                    width="100%"
                  >
                    <option value="" disabled hidden>
                      Province
                    </option>
                    {ProvinceData.map((province, index) => {
                      return (
                        <option value={province.name} key={index}>
                          {province.name}
                        </option>
                      );
                    })}
                  </Dropdown>
                </Col>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Postal Code (Required)</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    onWheel={(e) => e.target.blur()}
                    name="nightPostalCode"
                    onChange={handleInfoChange}
                    placeholder=""
                    required={true}
                    value={data.nightPostalCode}
                    width="100%"
                  />
                  {postalCodeError && (
                    <ValidationMessage>{postalCodeError}</ValidationMessage>
                  )}
                </Col>
              </Row>
              <Row>
                <Col $md={6} style={{ padding: "0 1% 0 0" }}>
                  <Label>Type of Residence (Required)</Label>
                  <Dropdown
                    name="nightAddressType"
                    onChange={handleInfoChange}
                    required={true}
                    value={data.nightAddressType}
                    width="100%"
                  >
                    <option value="" disabled hidden>
                      Please select
                    </option>
                    <option value="City">City</option>
                    <option value="Country Estate">Country Estate</option>
                    <option value="Estate">Estate</option>
                    <option value="Township Area">Township Area</option>
                    <option value="Urban">Urban</option>
                  </Dropdown>
                </Col>
              </Row>
            </ItemWrapper>
          </div>
        )}
      </FormBody>
      <ButtonWrapper>
        <button
          onClick={back}
          className="btn btn-outline-dark btn-md"
          type="button"
          style={{ margin: "0 2px" }}
        >
          Back
        </button>
        <button
          className="btn btn-dark btn-md"
          type="submit"
          style={{ margin: "0 2px" }}
        >
          Submit
        </button>
      </ButtonWrapper>
    </Form>
  );
}
